import { useLocation } from 'react-router-dom'

// boof add better typescript support
const useParamsFromUrl = (paramNames: string[]) => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    let retval: any = {}

    paramNames.forEach(paramName => {
        retval[`${paramName}Param`] = params.get(paramName)
    })

    return retval
}

export default useParamsFromUrl

import { RequestToDeliverCustomerOrderNoPaymentRequiredParameters } from '../../../interfaces'
import { fetchApi } from '../../../helpers/fetchApi'
import { ErrorMessages, CommandEndPoints } from '../../../enums'

export async function requestToDeliverCustomerOrderNoPaymentRequired({
  customerOrderId,
  dateDelivered,
  deliveryMilesDriven,
  deliveryGpsLat,
  deliveryGpsLon,
  deliverySurveyAnswers,
  deliveryBegan,
  deliveryEnded,
  deliveryNote,
  signatureGuid,
  signatureMimetype,
  signatureExtension,
  signatureUrl,
  signatureFrom,
  buildingImages,
}: RequestToDeliverCustomerOrderNoPaymentRequiredParameters) {
  try {
    return await fetchApi({
      path: CommandEndPoints.DeliverCustomerOrderNoPaymentRequired,
      body: {
        customer_order_id: customerOrderId,
        date_delivered: dateDelivered,
        delivery_miles_driven: deliveryMilesDriven,
        delivery_gps_lat: deliveryGpsLat,
        delivery_gps_lon: deliveryGpsLon,
        delivery_began: deliveryBegan,
        delivery_ended: deliveryEnded,
        delivery_note: deliveryNote,
        delivery_survey_answers: deliverySurveyAnswers,
        building_images: buildingImages,
        signature_guid: signatureGuid,
        signature_mimetype: signatureMimetype,
        signature_extension: signatureExtension,
        signature_url: signatureUrl,
        signature_from: signatureFrom,
      },
    })
  } catch (error) {
    console.error(`deliverCustomerOrderNoPaymentRequired: ${error}`)
    throw new Error(ErrorMessages.FailedToDeliverOrderWithNoPayment)
  }
}

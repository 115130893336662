import { db } from '../../../db'
import { DeliveryDetails } from '../../../db/models/DeliveryDetails'

export async function storeDeliveryDetails(details: DeliveryDetails) {
  const { customerOrderId, onlotInventoryOrderId, inventoryMoveOrderId } = details

  if (!!customerOrderId) {
    return await db.updateCustomerOrderDeliveryDetails(customerOrderId, details)
  } else if (!!inventoryMoveOrderId) {
    return await db.updateInventoryMoveOrderDeliveryDetails(inventoryMoveOrderId, details)
  } else if (!!onlotInventoryOrderId) {
    return await db.updateOnlotInventoryOrderDeliveryDetails(onlotInventoryOrderId, details)
  }
}

import { isEmpty } from 'lodash'
import { noCase } from 'change-case'

import { CommandEndPoints, ImageType } from '../../enums'
import { constructFileUploadFormData } from './helpers/constructFormData'
import { getGcsBucket } from './helpers/getGcsBucket'
import { getImageAttributes } from './helpers/getImageAttributes'
import { getJWT } from '../../../common/helpers/jwt'
import { isConnected } from '../isConnected'

export interface FileUploadAttributes {
  guid: string
  extension: string
  mimeType: string
  downloadUrls: string[]
}

export async function upload({
  base64Urls,
  imageType,
  customerOrderId,
  onlotOrderInventoryOrderId,
  inventoryMoveOrderId,
}: {
  base64Urls: string[]
  imageType: ImageType
  customerOrderId?: number
  onlotOrderInventoryOrderId?: number
  inventoryMoveOrderId?: number
}): Promise<FileUploadAttributes[]> {
  if (isConnected()) {
    const errorMessage = `Request to upload ${noCase(imageType)} image failed`

    try {
      const gcsBucket = getGcsBucket({
        imageType,
        customerOrderId,
        onlotOrderInventoryOrderId,
        inventoryMoveOrderId,
      })

      const formData = await constructFileUploadFormData({
        base64Urls,
        imageType,
      })

      const token = getJWT()

      // boof can I get it to work with fetchApi?
      const endpoint = `${process.env.REACT_APP_SERVER_URL}${CommandEndPoints.Upload}`
      const result = await fetch(endpoint, {
        headers: {
          authorization: `Bearer ${token}`,
          'gcs-bucket': gcsBucket,
        },
        body: formData,
        method: 'POST',
      })

      const uploadResults = await result.json()

      if (!!isEmpty(uploadResults)) {
        throw new Error(errorMessage)
      }

      return (uploadResults as any[]).reduce((acc, val) => {
        acc.push(getImageAttributes(val))
        return acc
      }, [])
    } catch (error) {
      console.error(`upload: ${error}`)
      throw new Error(errorMessage)
    }
  }
}

import { RequestToDeliverCustomerOrderNewCardParameters } from '../../../interfaces'
import { fetchApi } from '../../../helpers/fetchApi'
import { ErrorMessages, CommandEndPoints } from '../../../enums'

export async function requestToDeliverCustomerOrderNewCard({
  customerOrderId,
  paymentProcessingAccountId,
  dateDelivered,
  deliveryMilesDriven,
  deliveryGpsLat,
  deliveryGpsLon,
  deliveryBegan,
  deliveryEnded,
  deliveryNote,
  deliverySurveyAnswers,
  cardCvv,
  cardExpMonth,
  cardExpYear,
  cardToken,
  cardProfileId,
  buildingImages,
  signatureGuid,
  signatureMimetype,
  signatureExtension,
  signatureUrl,
  signatureFrom,
  paymentAmount,
  retref,
}: RequestToDeliverCustomerOrderNewCardParameters) {
  try {
    return await fetchApi({
      path: CommandEndPoints.DeliverCustomerOrderNewCard,
      body: {
        customer_order_id: customerOrderId,
        payment_processing_account_id: paymentProcessingAccountId,
        date_delivered: dateDelivered,
        delivery_miles_driven: deliveryMilesDriven,
        delivery_gps_lat: deliveryGpsLat,
        delivery_gps_lon: deliveryGpsLon,
        delivery_began: deliveryBegan,
        delivery_ended: deliveryEnded,
        delivery_note: deliveryNote,
        delivery_survey_answers: deliverySurveyAnswers,
        card_cvv: cardCvv,
        card_exp_month: cardExpMonth,
        card_exp_year: cardExpYear,
        card_token: cardToken,
        card_profile_id: cardProfileId,
        retref,
        building_images: buildingImages,
        signature_guid: signatureGuid,
        signature_mimetype: signatureMimetype,
        signature_extension: signatureExtension,
        signature_url: signatureUrl,
        signature_from: signatureFrom,
        payment_amount: paymentAmount,
      },
    })
  } catch (error) {
    console.error(`deliverCustomerOrderNewCard: ${error}`)
    throw new Error(ErrorMessages.FailedToDeliverOrderWithANewCard)
  }
}

import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import { isEmpty } from 'lodash'

import { CommandEndPoints, ErrorMessages, ImageType, LoadingStatus, QueryEndPoints } from '../enums'
import { DriverSettings } from '../../db/models/DriverSettings'
import { EditSettingsState } from '../../pages/edit-settings'
import { FileUploadAttributes } from '../helpers/upload/index'
import { NotificationsState } from '../../pages/settings/components/NotificationSettings'
import { RequestToUpdateDriverSettingsParameters } from '../interfaces'
import { fetchApi } from '../helpers/fetchApi'
import { isConnected } from '../helpers/isConnected'
import { upload } from '../helpers/upload'
import { getJWT } from '../../common/helpers/jwt'

interface SettingsState {
  updatingDriverSettingsLoadingStatus: LoadingStatus
  editSettings: {
    profileImage: string
  }
}
interface SettingsProviderProps {
  children: ReactNode
}

type ActionTypes =
  | 'SET_PROFILE_IMAGE'
  | 'UPDATE_DRIVER_SETTINGS_FAIL'
  | 'UPDATE_DRIVER_SETTINGS_START'
  | 'UPDATE_DRIVER_SETTINGS_SUCCESS'

type SettingsDispatch = ({
  type,
  value,
}: {
  type: ActionTypes
  value?: Partial<SettingsState>
}) => void

const SettingsStateContext = createContext<SettingsState | undefined>(undefined)
const SettingsDispatchContext = createContext<SettingsDispatch | undefined>(undefined)

SettingsStateContext.displayName = 'SettingsContext'
SettingsDispatchContext.displayName = 'SettingsDispatchContext'

function SettingsReducer(
  state: SettingsState,
  action: { type: ActionTypes; value?: Partial<SettingsState> }
) {
  const { type, value } = action

  switch (type) {
    case 'UPDATE_DRIVER_SETTINGS_START':
      return { ...state, updatingDriverSettingsLoadingStatus: LoadingStatus.Pending }
    case 'UPDATE_DRIVER_SETTINGS_FAIL':
      return {
        ...state,
        updatingDriverSettingsLoadingStatus: LoadingStatus.Rejected,
      }
    case 'UPDATE_DRIVER_SETTINGS_SUCCESS':
      return {
        ...state,
        updatingDriverSettingsLoadingStatus: LoadingStatus.Resolved,
      }
    case 'SET_PROFILE_IMAGE':
      return {
        ...state,
        editSettings: { profileImage: value.editSettings.profileImage },
      }
    default: {
      throw new Error(`Unhandled action: ${action}`)
    }
  }
}

function SettingsProvider({ children }: SettingsProviderProps) {
  const [state, dispatch] = useReducer(SettingsReducer, {
    updatingDriverSettingsLoadingStatus: LoadingStatus.Idle,
    editSettings: {
      profileImage: null,
    },
  })
  const jwt = getJWT()

  useEffect(() => {
    if (jwt) {
      refreshDriverSettings().catch(error => console.error(`refreshDriverSettings: ${error}`))
    }
  }, [jwt])

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  )
}

function useSettingsState() {
  const context = useContext(SettingsStateContext)
  if (context === undefined) {
    throw new Error(`useSettingsState must be used within a SettingsProvider`)
  }
  return context
}

function useSettingsDispatch() {
  const context = useContext(SettingsDispatchContext)
  if (context === undefined) {
    throw new Error(`useSettingsDispatch must be used within a SettingsProvider`)
  }
  return context
}

async function fetchDriverSettings() {
  const driverSettingsResponse: DriverSettings = await fetchApi({
    path: QueryEndPoints.DriverSettings,
  })

  return driverSettingsResponse
}

async function refreshDriverSettings() {
  try {
    return await fetchDriverSettings()
  } catch (error) {
    console.error(`refreshDriverSettings: ${error}`)
    throw new Error(ErrorMessages.FailedToRefreshDriverSettings)
  }
}

async function requestToUpdateDriverSettings({
  firstName,
  lastName,
  phoneNumber,
  email,
  profileImageAttributes,
  password,
}: RequestToUpdateDriverSettingsParameters) {
  const updateDriverSettingsResponse = await fetchApi({
    path: CommandEndPoints.UpdateDriverSettings,
    body: {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
      profile_image_attributes: profileImageAttributes,
      password,
    },
  })

  if (!!isEmpty(updateDriverSettingsResponse)) {
    throw new Error(ErrorMessages.FailedToSaveDriverSettings)
  }

  return updateDriverSettingsResponse
}

async function updateDriverSettings({
  dispatch,
  editSettingsState,
}: {
  dispatch: SettingsDispatch
  editSettingsState: EditSettingsState
}) {
  if (isConnected()) {
    dispatch({ type: 'UPDATE_DRIVER_SETTINGS_START' })
    try {
      let profileImageUploadResponse: FileUploadAttributes
      if (editSettingsState.newImage) {
        const [uploadResponse] = await upload({
          base64Urls: [editSettingsState.profileImage],
          imageType: ImageType.ProfileImage,
        })
        profileImageUploadResponse = uploadResponse
      }

      const updateDriverSettingsResponse = await requestToUpdateDriverSettings({
        firstName: editSettingsState.firstName,
        lastName: editSettingsState.lastName,
        email: editSettingsState.email,
        phoneNumber: editSettingsState.phoneNumber,
        ...(profileImageUploadResponse && {
          profileImageAttributes: {
            guid: profileImageUploadResponse.guid,
            mime_type: profileImageUploadResponse.mimeType,
            extension: profileImageUploadResponse.extension,
            download_urls: profileImageUploadResponse.downloadUrls,
          },
        }),
        ...(editSettingsState.password && { password: editSettingsState.password }),
      })

      dispatch({ type: 'UPDATE_DRIVER_SETTINGS_SUCCESS' })
      return updateDriverSettingsResponse
    } catch (error) {
      console.error(`updateDriverSettings: ${error}`)
      dispatch({ type: 'UPDATE_DRIVER_SETTINGS_FAIL' })
      throw new Error(ErrorMessages.FailedToSaveDriverSettings)
    }
  }
}

async function updateEmailNotificationSettings({
  notificationSettings,
}: {
  notificationSettings: NotificationsState
}) {
  if (isConnected()) {
    try {
      const updateDriverNotificationSettingsResponse = await fetchApi({
        path: CommandEndPoints.UpdateDriverEmailNotifications,
        body: {
          email_notifications_new_order_assigned: notificationSettings.orderAssigned,
          email_notifications_order_reassigned: notificationSettings.orderReassigned,
          email_notifications_order_finished_at_shop: notificationSettings.orderFinishedAtShop,
        },
      })

      if (!!isEmpty(updateDriverNotificationSettingsResponse)) {
        throw new Error(ErrorMessages.FailedToSaveDriverNotificationSettings)
      }

      return updateDriverNotificationSettingsResponse
    } catch (error) {
      console.error(`updateEmailNotificationSettings: ${error}`)
      throw new Error(ErrorMessages.FailedToSaveDriverNotificationSettings)
    }
  }
}

function setProfileImageOnEditSettingsPage({
  dispatch,
  image,
}: {
  dispatch: SettingsDispatch
  image: string
}) {
  dispatch({
    type: 'SET_PROFILE_IMAGE',
    value: { editSettings: { profileImage: image } },
  })
}

export {
  SettingsProvider,
  useSettingsState,
  useSettingsDispatch,
  refreshDriverSettings,
  updateDriverSettings,
  setProfileImageOnEditSettingsPage,
  updateEmailNotificationSettings,
}

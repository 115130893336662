import { useHistory } from 'react-router-dom'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { CloudOff as CloudOffIcon } from '@material-ui/icons'
import { PagePaths } from '../../context/enums'
import { FC } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '1em',
      background: 'white',
      margin: theme.spacing(1),
      color: '#aaa',
      textAlign: 'center',
      minHeight: '275px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
    },
    topSpacing: {
      marginTop: theme.spacing(1),
    },
  })
)

interface Props {
  error?: any
  hideResetButton?: boolean
}

export const ErrorFallback: FC<Props> = ({ error, hideResetButton }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.wrapper}>
      <CloudOffIcon />
      <pre>{`Something went wrong${!!error ? `:${error}` : '.'}`}</pre>
      {!hideResetButton ? (
        <Button
          onClick={() => {
            history.push(PagePaths.Home)
            window.location.reload()
          }}
          variant="contained"
          disableElevation
          className={classes.topSpacing}
          size="small"
        >
          Back to home page
        </Button>
      ) : null}
    </div>
  )
}

import { Suspense, lazy, FC } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { SnackbarProvider } from 'notistack'
import { ErrorBoundary } from 'react-error-boundary'

import theme from './theme'
import Uploader from './Uploader'
import './styles.scss'
import { ErrorFallback } from './common/components/ErrorFallback'
import { useServiceWorker } from './UseServiceWorker'
import ReactPWAInstallProvider from 'react-pwa-install'
import { beamerInitialize } from './third-party/index'
import { IndexedDBIsNotSupportedBackDrop } from './common/components/IndexedDBIsNotSupportedBackDrop'
import { DeliveryDetailsProvider } from './context/delivery-details'
import { GlobalProvider, useGlobalState } from './context/global'
import { PagePaths, UserRole } from './context/enums'
import { CircularLoader } from './common/components/CircularLoader'
import { SettingsProvider } from './context/settings'
import { QueryClient, QueryClientProvider } from 'react-query'
import { FiltersProvider } from './context/filters'

const SignInPage = lazy(() => import('./pages/sign-in'))
const OrderListPage = lazy(() => import('./pages/order-list'))
const CustomerOrderDetailsPage = lazy(() => import('./pages/customer-order-details'))
const OnlotInventoryOrderDetailsPage = lazy(() => import('./pages/onlot-inventory-order-details'))
const InventoryMoveOrderDetailsPage = lazy(() => import('./pages/inventory-move-order-details'))
const OrderMapPage = lazy(() => import('./pages/order-map'))
const CustomerOrderScheduleDetailPage = lazy(
  () => import('./pages/customer-order-schedule-details')
)
const OnlotInventoryOrderScheduleDetailPage = lazy(
  () => import('./pages/onlot-inventory-order-schedule-details')
)
const InventoryMoveOrderScheduleDetailPage = lazy(
  () => import('./pages/inventory-move-order-schedule-details')
)
const DeliveryDetailsPage = lazy(() => import('./pages/delivery-details'))
const ScheduledPage = lazy(() => import('./pages/scheduled'))
const DeliveryLogsPage = lazy(() => import('./pages/delivery-log'))
const TechSupportPage = lazy(() => import('./pages/tech-support'))
const NotificationsPage = lazy(() => import('./pages/notifications'))
const ScheduleOrderPage = lazy(() => import('./pages/schedule-order'))
const SettingsPage = lazy(() => import('./pages/settings'))
const EditSettingsPage = lazy(() => import('./pages/edit-settings'))

const PrivateRoute: any = (props: any) => {
  const { component: Component, authed, ...rest } = props

  return (
    <Route
      {...rest}
      render={props =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: PagePaths.SignIn,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const DeliveryDetailsPageWithContext = () => {
  return (
    <DeliveryDetailsProvider>
      <DeliveryDetailsPage />
    </DeliveryDetailsProvider>
  )
}

const UploaderWithContext = () => {
  return (
    <DeliveryDetailsProvider>
      <Uploader />
    </DeliveryDetailsProvider>
  )
}

const EditSettingsPageWithProps = (props: any) => {
  return <EditSettingsPage driverSettings={props?.location?.state?.driverSettings} />
}

const Root: FC = () => {
  useServiceWorker()
  const { authenticated, decodedToken } = useGlobalState()

  if ('safari' in window) {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      window.history.go(1)
    }
  }

  if (decodedToken?.account_id && decodedToken?.company) {
    const name = decodedToken?.name?.trim()?.split(' ')
    const firstName = name?.[0]?.trim() || ''
    const lastName = name?.[name?.length - 1]?.trim() || ''

    beamerInitialize({
      user_id: `${decodedToken.account_id}`,
      user_email: decodedToken.email,
      user_firstname: firstName,
      user_lastname: lastName,
      filter: decodedToken.role,
      company: decodedToken.company,
    })
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        <Suspense fallback={<CircularLoader />}>
          <PrivateRoute
            exact
            authed={authenticated}
            path={PagePaths.Home}
            component={OrderListPage}
          />
          <PrivateRoute
            exact
            path={PagePaths.OrderMap}
            authed={authenticated}
            component={OrderMapPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={PagePaths.Notifications}
            component={NotificationsPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.CustomerOrderDetails}/:id`}
            component={CustomerOrderDetailsPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.OnlotInventoryOrderDetails}/:id`}
            component={OnlotInventoryOrderDetailsPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.InventoryMoveOrderDetails}/:id`}
            component={InventoryMoveOrderDetailsPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={PagePaths.DeliveryLog}
            component={DeliveryLogsPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={PagePaths.Scheduled}
            component={ScheduledPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.ScheduleOrder}/:id`}
            component={ScheduleOrderPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.CustomerOrderScheduleDetails}/:id`}
            component={CustomerOrderScheduleDetailPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.OnlotInventoryOrderScheduleDetails}/:id`}
            component={OnlotInventoryOrderScheduleDetailPage}
          />
          <PrivateRoute
            exact
            authed={authenticated}
            path={`${PagePaths.InventoryMoveOrderScheduleDetails}/:id`}
            component={InventoryMoveOrderScheduleDetailPage}
          />
          <Route
            exact
            path={PagePaths.SignIn}
            render={p => {
              if (authenticated) {
                return (
                  <Redirect
                    to={{
                      pathname: PagePaths.Home,
                      state: { from: PagePaths.SignIn },
                    }}
                  />
                )
              }
              return <SignInPage {...p} />
            }}
          />
          <PrivateRoute
            exact
            path={`${PagePaths.DeliveryDetails}/:id`}
            authed={authenticated}
            component={DeliveryDetailsPageWithContext}
          />
          <PrivateRoute
            exact
            path={PagePaths.TechSupport}
            authed={authenticated}
            component={TechSupportPage}
          />
          <PrivateRoute
            exact
            path={PagePaths.Settings}
            authed={authenticated}
            component={SettingsPage}
          />
          <PrivateRoute
            exact
            path={PagePaths.EditSettings}
            authed={authenticated}
            component={EditSettingsPageWithProps}
          />
        </Suspense>
      </Switch>
    </ErrorBoundary>
  )
}

export const App: FC = () => {
  const queryClient = new QueryClient()
  const whitelistedRoles = [UserRole.SHEDSUITE_DRIVER]

  return (
    <ThemeProvider theme={theme}>
      <ReactPWAInstallProvider>
        <CssBaseline />
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <GlobalProvider whitelistRoles={whitelistedRoles}>
            <SettingsProvider>
              <FiltersProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <SnackbarProvider maxSnack={3} preventDuplicate={true}>
                    <Router>
                      <IndexedDBIsNotSupportedBackDrop />
                      <UploaderWithContext />
                      <Root />
                    </Router>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </FiltersProvider>
            </SettingsProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </ReactPWAInstallProvider>
    </ThemeProvider>
  )
}

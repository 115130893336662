import { match } from 'ts-pattern'

const subdomain = window.location.host.split('.').at(0)
const tld = window.location.host.split('.').at(-1)

export const isProduction = tld === 'com'

export const isBranch = tld === 'net'

export const environment = match(subdomain)
  .with('driver-app', () => 'Production' as const)
  .with('master', () => 'Master' as const)
  .otherwise(() => 'Development' as const)

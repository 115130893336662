import { Emails, OrderType, CommandEndPoints } from '../../enums'
import { fetchApi } from '../fetchApi'
import { isProduction } from '../../../common/helpers/environment'
import { sendOfficeAdminOrderDeliveredEmail } from './sendOfficeAdminOrderDeliveredEmail'
import { sendCustomerOrderDeliveredEmail } from './sendCustomerOrderDeliveredEmail'
import { sendCustomerOrderScheduledEmail } from './sendCustomerOrderScheduledEmail'
import { sendRtoOrderDeliveredEmail } from './sendRtoOrderDeliveredEmail'
import { sendDriverReassignEmail } from './sendDriverReassignEmail'
import { sendDriverAssignedEmail } from './sendDriverAssignedEmail'
import { TE, pipe } from '../../../common/helpers/fp-ts'

export type EmailNotificationInput = {
  type: Emails
  orderId: number
  orderType?: OrderType
  driver_id?: number
  deliveryCertificateUrl?: string
}

export type SendEmailInput = {
  recipient: string
  subject: string
  message?: string
  noAuth?: boolean
  messageIsHtml?: boolean
  tags?: string[]
  variables?: any
  template?: string
} & (
  | {
      fromNoReply: true
    }
  | {
      fromNoReply: false
      senderName: string
      senderEmail: string
    }
)

export const sendEmail = async (
  input: SendEmailInput
): Promise<{
  success: boolean
  error?: string
  mailgunResponse: any
}> => {
  if (!isProduction) return

  return await fetchApi({
    path: CommandEndPoints.SendEmail,
    body: input,
    baseUrl: process.env.REACT_APP_SERVER_URL,
    customHeaders: {
      'content-type': 'application/json',
    },
  })
}

export async function sendEmailNotification(input: EmailNotificationInput) {
  switch (input.type) {
    case Emails.ShedSuiteOfficeAdminOrderDelivered:
      return await sendOfficeAdminOrderDeliveredEmail(input)
    case Emails.ShedSuiteCustomerOrderDelivered:
      return pipe(
        sendCustomerOrderDeliveredEmail(input),
        TE.match(
          error =>
            console.warn(`Failed to send customer order delivered email\n Error: ${error.message}`),
          () => console.log('Successfully sent customer order delivered email')
        )
      )()
    case Emails.ShedSuiteRtoOrderDelivered:
      return pipe(
        sendRtoOrderDeliveredEmail(input),
        TE.match(
          error =>
            console.warn(`Failed to send RTO order delivered email\n Error: ${error.message}`),
          () => console.log('Successfully sent RTO order delivered email(s)')
        )
      )()
    case Emails.ShedSuiteCustomerOrderScheduled:
      return pipe(
        sendCustomerOrderScheduledEmail(input),
        TE.match(
          error =>
            console.warn(`Failed to send customer order scheduled email\n Error: ${error.message}`),
          () => console.log('Successfully sent customer order scheduled email')
        )
      )()
    case Emails.ShedSuiteDriverReassign:
      return await sendDriverReassignEmail(input)
    case Emails.ShedSuiteDriverAssigned:
      return await sendDriverAssignedEmail(input)
    default:
      console.log(`Can't send system email with email type ${input.type}`)
  }
}

import jwtDecode from 'jwt-decode'
import useCookie from 'react-use-cookie'
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import { includes } from 'lodash'

import { LoadingStatus, CommandEndPoints, QueryEndPoints } from '../enums'
import { UserRole } from '../enums'
import { deleteCookie, setCookie } from '../../common/helpers/cookies'
import { fetchApi } from '../helpers/fetchApi'
import { isBranch, isProduction } from '../../common/helpers/environment'
import { resetIndexedDBDatabase } from '../../db/utils/resetIndexedDBDatabase'
import { useQuery } from 'react-query'
import { getJWT } from '../../common/helpers/jwt'

export const JWT_STORAGE_KEY = 'jwt'
export const IMPERSONATE_JWT_STORAGE_KEY = 'impersonate-jwt'

type GlobalState = {
  loadingStatus: LoadingStatus
  decodedToken: DecodedToken
  impersonateToken: string
  token: string
  authenticated: boolean
  error: string
  newVersionAvailable: boolean
  waitingServiceWorker: ServiceWorker
}

type GlobalProviderProps = {
  children: ReactNode
  whitelistRoles: UserRole[]
}

export type DecodedToken = {
  role: string
  account_id: number
  company_id: number
  company_user_id: number
  renewal_token: string
  exp: number
  email?: string
  name?: string
  company?: string
  financial_user_id?: number
  financial_company_id?: number
  original_account_id?: number
  is_carport_user?: boolean
  iat?: number
  aud?: string
  iss: string
}

type ActionTypes =
  | 'AUTHENTICATE_FETCH_START'
  | 'AUTHENTICATE_FETCH_FAIL'
  | 'AUTHENTICATE_FETCH_SUCCESS'
  | 'SET_DECODED_TOKEN'
  | 'SET_AUTHENTICATED'
  | 'LOGIN'
  | 'UPDATE_SW'
  | 'LOG_OUT'
  | 'SET_SCROLLABLE_PAGE_CONTAINER_REF'
type GlobalDispatch = ({ type, value }: { type: ActionTypes; value?: Partial<GlobalState> }) => void

const GlobalStateContext = createContext<GlobalState | undefined>(undefined)
const GlobalDispatchContext = createContext<GlobalDispatch | undefined>(undefined)

GlobalStateContext.displayName = 'GlobalContext'
GlobalDispatchContext.displayName = 'GlobalDispatchContext'

function GlobalReducer(
  state: GlobalState,
  action: { type: ActionTypes; value?: Partial<GlobalState> }
) {
  const { type, value } = action

  switch (type) {
    case 'AUTHENTICATE_FETCH_START':
      return { ...state, loadingStatus: LoadingStatus.Pending, error: null }
    case 'AUTHENTICATE_FETCH_SUCCESS':
      return { ...state, loadingStatus: LoadingStatus.Resolved, error: null }
    case 'AUTHENTICATE_FETCH_FAIL':
      return { ...state, loadingStatus: LoadingStatus.Rejected, ...value }
    case 'SET_DECODED_TOKEN':
      return { ...state, ...value }
    case 'SET_AUTHENTICATED':
      return { ...state, ...value }
    case 'LOGIN':
      return { ...state, ...value }
    case 'UPDATE_SW':
      return { ...state, ...value }
    case 'LOG_OUT':
      return { ...state, ...value }
    default: {
      throw new Error(`Unhandled action: ${action}`)
    }
  }
}

function GlobalProvider({ children, whitelistRoles }: GlobalProviderProps) {
  const [state, dispatch] = useReducer(GlobalReducer, {
    loadingStatus: LoadingStatus.Idle,
    authenticated: true,
    decodedToken: undefined,
    impersonateToken: undefined,
    token: undefined,
    error: null,
    newVersionAvailable: false,
    waitingServiceWorker: null,
  })
  const [impersonateToken] = useCookie(IMPERSONATE_JWT_STORAGE_KEY)
  const [token] = useCookie(JWT_STORAGE_KEY)
  useQuery(
    QueryEndPoints.CompanyUserIsRemoved,
    async () => {
      if (!getJWT()) return

      const companyUserIsRemoved = await fetchApi({
        path: QueryEndPoints.CompanyUserIsRemoved,
      })

      if (companyUserIsRemoved) {
        await unauthenticate({ dispatch })
        window.location.pathname = '/'
      }
    },
    {
      refetchInterval: 1000 * 60 * 5,
    }
  )

  useEffect(() => {
    if (!!token && !impersonateToken) {
      const dt: DecodedToken = jwtDecode(token)
      dispatch({ type: 'SET_DECODED_TOKEN', value: { decodedToken: dt } })
      const expires = new Date(dt.exp * 1000).getTime()
      const now = new Date().getTime()
      const fifteenminutes = 15 * 60 * 1000
      const valid = expires > now
      const soon = Math.abs(expires - now) < fifteenminutes
      const { role } = dt
      if (valid && !soon && (!whitelistRoles || includes(whitelistRoles, role))) {
        dispatch({ type: 'SET_AUTHENTICATED', value: { authenticated: true } })
      } else {
        dispatch({ type: 'SET_AUTHENTICATED', value: { authenticated: false } })
      }
    } else if (!!impersonateToken) {
      clearAllStorage()
      dispatch({
        type: 'SET_DECODED_TOKEN',
        value: { decodedToken: jwtDecode(impersonateToken) },
      })
      dispatch({ type: 'SET_AUTHENTICATED', value: { authenticated: true } })
    } else {
      dispatch({ type: 'SET_AUTHENTICATED', value: { authenticated: false } })
    }
  }, [impersonateToken, token, whitelistRoles])

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

function useGlobalState() {
  const context = useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error(`useGlobalState must be used within a GlobalProvider`)
  }
  return context
}

function useGlobalDispatch() {
  const context = useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error(`useGlobalDispatch must be used within a GlobalProvider`)
  }
  return context
}

async function authenticate({
  dispatch,
  email,
  password,
}: {
  dispatch: GlobalDispatch
  email: string
  password: string
}) {
  dispatch({ type: 'AUTHENTICATE_FETCH_START' })
  try {
    const token = await fetchApi({
      path: CommandEndPoints.Login,
      body: { email, password },
    })

    if (typeof token === 'string') {
      setCookie(JWT_STORAGE_KEY, token)
      const decodedToken: DecodedToken = jwtDecode(token)
      dispatch({
        type: 'LOGIN',
        value: {
          decodedToken,
          token: token,
          authenticated: true,
        },
      })
      dispatch({ type: 'AUTHENTICATE_FETCH_SUCCESS' })
    }
  } catch (error) {
    dispatch({
      type: 'AUTHENTICATE_FETCH_FAIL',
      value: { error: error?.message || 'An error occured', token: '' },
    })
  }
}

async function unauthenticate({ dispatch }: { dispatch: GlobalDispatch }) {
  deleteCookie(JWT_STORAGE_KEY)
  deleteCookie(IMPERSONATE_JWT_STORAGE_KEY)
  dispatch({ type: 'LOG_OUT', value: { authenticated: false } })
}

function clearAllStorage() {
  resetIndexedDBDatabase()

  Object.entries(localStorage).forEach(row => {
    const key = row[0]
    if (key === 'token') return
    window.localStorage.removeItem(key)
  })
}

function reloadPageWithNewServiceWorker({
  dispatch,
  waitingServiceWorker,
}: {
  dispatch: GlobalDispatch
  waitingServiceWorker: ServiceWorker
}) {
  waitingServiceWorker?.postMessage({ type: 'SKIP_WAITING' })
  dispatch({ type: 'UPDATE_SW', value: { newVersionAvailable: false } })
  window.location.reload()
}

function onSWUpdate({
  dispatch,
  registration,
}: {
  dispatch: GlobalDispatch
  registration: ServiceWorkerRegistration
}) {
  dispatch({
    type: 'UPDATE_SW',
    value: { newVersionAvailable: true, waitingServiceWorker: registration.waiting },
  })
}

function endImpersonation({ dispatch }: { dispatch: GlobalDispatch }) {
  deleteCookie(IMPERSONATE_JWT_STORAGE_KEY)
  clearAllStorage()

  if (isBranch) {
    window.location.href = window.location.origin.replace('-driver-app', '')
  } else if (isProduction) {
    window.location.href = window.location.origin.replace('driver-app', 'app')
  } else {
    unauthenticate({ dispatch })
  }
}

export {
  GlobalProvider,
  useGlobalState,
  useGlobalDispatch,
  authenticate,
  unauthenticate,
  clearAllStorage,
  reloadPageWithNewServiceWorker,
  onSWUpdate,
  endImpersonation,
}

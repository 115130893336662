import { DeliveryDetails } from '../../../../db/models/DeliveryDetails'
import { ErrorMessages } from '../../../enums'
import { commonDeliveryDetailsValidation } from './commonDeliveryDetailsValidation'

export function validateDeliverCustomerOrderSavedCheckRequiredFields(
  deliveryDetails: DeliveryDetails
) {
  commonDeliveryDetailsValidation({ deliveryDetails })

  if (!deliveryDetails.customerOrderId) {
    throw new Error(ErrorMessages.NoOrderIdFound)
  } else if (!deliveryDetails.deliveryGpsLat || !deliveryDetails.deliveryGpsLon) {
    throw new Error(ErrorMessages.DeliveryLocationNotSet)
  } else if (!deliveryDetails.happyListResults) {
    throw new Error(ErrorMessages.DeliverySurveyAnswersNotSet)
  } else if (!deliveryDetails.signatureDataUrl) {
    throw new Error(ErrorMessages.DeliverySignatureRequired)
  } else if (!deliveryDetails.customerOrderSavedCheckId) {
    throw new Error(ErrorMessages.SavedCheckDoesNotHaveAnId)
  } else if (!!deliveryDetails.paymentAmount && isNaN(+deliveryDetails.paymentAmount)) {
    throw new Error(ErrorMessages.PaymentAmountIsNan)
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { register } from './serviceWorkerRegistration'

import { onSWUpdate, useGlobalDispatch } from './context/global'
import { useQueryClient } from 'react-query'

/*
    This custom hooks should only be used in the root of the application
    to register the service worker
*/
export const useServiceWorker = () => {
    const globalDispatch = useGlobalDispatch()
    const queryClient = useQueryClient()

    useEffect(() => {
        register({
            onUpdate: (registration) => {
                queryClient.clear()
                onSWUpdate({ dispatch: globalDispatch, registration })
            }
        })
    }, [])
}

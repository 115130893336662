import { isObject } from 'lodash'
import axios from 'axios'
import { match } from 'ts-pattern'

import { ErrorMessages, Errors, QueryEndPoints, CommandEndPoints, Service } from '../enums'
import { delay } from '../../common/helpers/delay'
import { getJWT } from '../../common/helpers/jwt'
import { TE, pipe } from '../../common/helpers/fp-ts'
import { ProblemDetails } from '@shedsuite-gitlab/payments'

export const fetchApi = async ({
  path,
  body = {},
  customHeaders,
  baseUrl = process.env.REACT_APP_API_URL,
}: {
  path: string
  body?: any
  customHeaders?: HeadersInit
  baseUrl?: string
}) => {
  let methodIsGet = false
  const token = getJWT()

  if (!token && path !== CommandEndPoints.Login) {
    throw new Error(ErrorMessages.NotAuthenticated)
  } else if (
    path === QueryEndPoints.EmailCustomerOrderDelivered ||
    path === QueryEndPoints.EmailCustomerOrderScheduled ||
    path === QueryEndPoints.EmailDriverAssignedCustomerOrder ||
    path === QueryEndPoints.EmailDriverAssignedOnlotInventoryOrder ||
    path === QueryEndPoints.EmailDriverReassignedCustomerOrder ||
    path === QueryEndPoints.EmailOfficeAdminCustomerOrderDelivered ||
    path === QueryEndPoints.EmailOfficeAdminOnlotInventoryOrderDelivered ||
    path === CommandEndPoints.GenerateDeliveryCertificate
  ) {
    await delay(7000)
  }

  if (path.match(/^\/secure\/report\//)) {
    baseUrl = process.env.REACT_APP_GW_URL
  } else if (path.match(/^\/api\//) || path.match(/^\/rmaembed\//)) {
    baseUrl = process.env.REACT_APP_REMIX_APP_URL
    methodIsGet = true
  }

  let headers: any

  if (!!customHeaders) {
    headers = {
      ...(token && { authorization: `Bearer ${token}` }),
      ...customHeaders,
    }
  } else {
    headers = {
      ...(token && { authorization: `Bearer ${token}` }),
      'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const response = await fetch(`${baseUrl}${path}`, {
    headers,
    ...(methodIsGet
      ? {
          method: 'GET',
        }
      : {
          method: 'POST',
          body: isObject(body) ? JSON.stringify(body) : body,
        }),
  })

  if (response.status !== 200) {
    const t = await response.text()

    if (t === Errors.Login) {
      throw new Error(ErrorMessages.IncorrectEmailOrPassword)
    }

    if (response.status === 404) {
      throw new Error(ErrorMessages.NotFound)
    }

    if (response.status === 401) {
      throw new Error(ErrorMessages.NotAuthorized)
    }

    throw new Error(ErrorMessages.GeneralError)
  }

  if (path.includes(QueryEndPoints.LogListExport)) {
    return response
  }

  const retval = await response.json()

  return retval
}

type SafeFetchOptions =
  | {
      method: 'GET'
      endpoint: QueryEndPoints
      service: Service
      urlSearchParams?: URLSearchParams
    }
  | {
      method: 'POST'
      endpoint: CommandEndPoints | QueryEndPoints.ProfileInquery
      body: Record<string, unknown>
      service: Service
    }

const createHttpClient = () =>
  axios.create({
    headers: {
      'X-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${getJWT()}`,
    },
  })

const getBaseUrl = (input: SafeFetchOptions) =>
  match(input.service)
    .with(Service.REMIX, () => process.env.REACT_APP_REMIX_APP_URL)
    .with(Service.REPORT, () => process.env.REACT_APP_GW_URL)
    .with(Service.CARD_CONNECT, () => process.env.REACT_APP_API_URL)
    .with(Service.WEB_APP, () => process.env.REACT_APP_SERVER_URL)
    .with(Service.DRIVER, () => process.env.REACT_APP_API_URL)
    .with(Service.GOAPI, () => '') // defaults to using window.location.origin
    .exhaustive()

const createAbsoluteURL = (input: SafeFetchOptions) =>
  pipe(input, getBaseUrl, baseUrl => baseUrl.concat(input.endpoint))

export const isProblemDetails = (error: unknown): error is ProblemDetails =>
  typeof error === 'object' && 'details' in error

export const createError = (error: unknown) =>
  error instanceof Error ? error : new Error(String(error))

const endpointsToDelay: (QueryEndPoints | CommandEndPoints)[] = [
  QueryEndPoints.EmailCustomerOrderScheduled,
]

export const safeFetch = <T>(input: SafeFetchOptions) =>
  TE.tryCatch(
    async () => {
      const httpClient = createHttpClient()

      if (endpointsToDelay.includes(input.endpoint)) await delay(7000)

      return match(input)
        .with({ method: 'GET' }, input =>
          httpClient
            .get<T>(createAbsoluteURL(input), {
              params: input.urlSearchParams,
            })
            .then(res => res.data)
        )
        .with({ method: 'POST' }, input =>
          httpClient.post<T>(createAbsoluteURL(input), input.body).then(res => res.data)
        )
        .exhaustive()
    },
    error => (isProblemDetails(error) ? createError(error.details) : createError(error))
  )

import { TE, pipe } from '../common/helpers/fp-ts'
import { normalize_materializer_payload_fields } from '../common/helpers/materializer'
import { QueryEndPoints, Service } from '../context/enums'
import { safeFetch } from '../context/helpers/fetchApi'
import { constructUrlSearchParams } from '../context/helpers/url'
import {
  CustomerOrderDeliveryDetailsRemote,
  InventoryMoveOrderDeliveryDetailsRemote,
  InventoryOrderDeliveryDetailsRemote,
} from '../db/models/DeliveryDetails'
import {
  CustomerOrderDetails,
  InventoryMoveOrderDetails,
  OnlotInventoryOrderDetails,
} from '../db/models/OrderDetails'
import { OrderListItem } from '../db/models/OrderList'
import { SavedCheck } from '../domain'
import { operations } from '../types/goapi'

const getOrderList = () =>
  pipe(
    safeFetch<OrderListItem[]>({
      method: 'GET',
      endpoint: QueryEndPoints.OrderList,
      service: Service.REMIX,
    }),
    TE.map(normalize_materializer_payload_fields)
  )

const getOrderMap = () =>
  pipe(
    safeFetch<operations['driver-app-order-map']['responses']['200']['schema']>({
      method: 'GET',
      endpoint: QueryEndPoints.OrderMap,
      service: Service.GOAPI,
    }),
    TE.map(om => ({
      orders: normalize_materializer_payload_fields(om.orders),
      dealers: normalize_materializer_payload_fields(om.dealers),
      shops: normalize_materializer_payload_fields(om.shops),
    }))
  )

const getCustomerOrderDetails = (orderId: string | number) =>
  safeFetch<CustomerOrderDetails>({
    method: 'GET',
    endpoint: QueryEndPoints.CustomerOrderDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ customerOrderId: string | number }>({
      customerOrderId: String(orderId),
    }),
  })

const getOnlotInventoryOrderDetails = (orderId: string | number) =>
  safeFetch<OnlotInventoryOrderDetails>({
    method: 'GET',
    endpoint: QueryEndPoints.OnlotInventoryOrderDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ onlotInventoryOrderId: string | number }>({
      onlotInventoryOrderId: String(orderId),
    }),
  })

const getInventoryMoveOrderDetails = (orderId: string | number) =>
  safeFetch<InventoryMoveOrderDetails>({
    method: 'GET',
    endpoint: QueryEndPoints.InventoryMoveOrderDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ inventoryMoveOrderId: string | number }>({
      inventoryMoveOrderId: String(orderId),
    }),
  })

const getOnlotInventoryOrderDeliveryDetails = (orderId: string | number) =>
  safeFetch<InventoryOrderDeliveryDetailsRemote>({
    method: 'GET',
    endpoint: QueryEndPoints.InventoryOrderDeliveryDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ onlotInventoryOrderId: string | number }>({
      onlotInventoryOrderId: String(orderId),
    }),
  })

const getInventoryMoveOrderDeliveryDetails = (orderId: string | number) =>
  safeFetch<InventoryMoveOrderDeliveryDetailsRemote>({
    method: 'GET',
    endpoint: QueryEndPoints.InventoryMoveOrderDeliveryDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ inventoryMoveOrderId: string | number }>({
      inventoryMoveOrderId: String(orderId),
    }),
  })

const getCustomerOrderDeliveryDetails = (orderId: string | number) =>
  safeFetch<CustomerOrderDeliveryDetailsRemote>({
    method: 'GET',
    endpoint: QueryEndPoints.CustomerOrderDeliveryDetails,
    service: Service.REMIX,
    urlSearchParams: constructUrlSearchParams<{ customer_order_id: string | number }>({
      customer_order_id: String(orderId),
    }),
  })

const getRtoOrderDeliveredEmailInformation = (orderId: string | number) =>
  safeFetch<operations['rto-order-delivered-email-information']['responses']['200']['schema']>({
    endpoint: QueryEndPoints.EmailRtoOrderDelivered,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['rto-order-delivered-email-information']['parameters']['query']
    >({
      'customer-order-id': String(orderId),
    }),
  })

const getOfficeAdminInventoryMoveOrderDeliveredEmailInformation = (orderId: string | number) =>
  safeFetch<
    operations['office-admin-inventory-move-order-delivered-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailOfficeAdminInventoryMoveOrderDelivered,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['office-admin-inventory-move-order-delivered-email-information']['parameters']['query']
    >({
      'inventory-move-order-id': String(orderId),
    }),
  })

const getOfficeAdminOnlotInventoryOrderDeliveredEmailInformation = (orderId: string | number) =>
  safeFetch<
    operations['office-admin-onlot-inventory-order-delivered-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailOfficeAdminOnlotInventoryOrderDelivered,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['office-admin-onlot-inventory-order-delivered-email-information']['parameters']['query']
    >({
      'onlot-inventory-order-id': String(orderId),
    }),
  })

const getCustomerOrderScheduledEmailInformation = (orderId: string | number) =>
  safeFetch<operations['customer-order-scheduled-email-information']['responses']['200']['schema']>(
    {
      endpoint: QueryEndPoints.EmailCustomerOrderScheduled,
      service: Service.GOAPI,
      method: 'GET',
      urlSearchParams: constructUrlSearchParams<
        operations['customer-order-scheduled-email-information']['parameters']['query']
      >({
        'customer-order-id': String(orderId),
      }),
    }
  )

const getDriverReassignedOnlotInventoryOrderEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['driver-reassigned-onlot-inventory-order-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverReassignedOnlotOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['driver-reassigned-onlot-inventory-order-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'onlot-inventory-order-id': String(orderId),
    }),
  })

const getDriverAssignedOnlotInventoryOrderEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['driver-assigned-onlot-inventory-order-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverAssignedOnlotInventoryOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['driver-assigned-onlot-inventory-order-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'onlot-inventory-order-id': String(orderId),
    }),
  })

const getDriverReassignedInventoryMoveOrderEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['driver-reassigned-inventory-move-order-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverReassignedInventoryMoveOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['driver-reassigned-inventory-move-order-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'inventory-move-order-id': String(orderId),
    }),
  })

const getDriverAssignedInventoryMoveOrderEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['driver-assigned-inventory-move-order-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverAssignedInventoryMoveOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['driver-assigned-inventory-move-order-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'inventory-move-order-id': String(orderId),
    }),
  })

const getSavedCustomerOrderChecks = (orderId: string | number) =>
  safeFetch<SavedCheck[]>({
    endpoint: QueryEndPoints.SavedCustomerOrderChecks,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['saved-customer-order-checks']['parameters']['query']
    >({
      'customer-order-id': String(orderId),
    }),
  })

const getCustomerOrderDriverReassignedEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['customer-order-driver-reassigned-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverReassignedCustomerOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['customer-order-driver-reassigned-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'customer-order-id': String(orderId),
    }),
  })

const getCustomerOrderDriverAssignedEmailInformation = (
  orderId: string | number,
  driverId: string | number
) =>
  safeFetch<
    operations['customer-order-driver-assigned-email-information']['responses']['200']['schema']
  >({
    endpoint: QueryEndPoints.EmailDriverAssignedCustomerOrder,
    service: Service.GOAPI,
    method: 'GET',
    urlSearchParams: constructUrlSearchParams<
      operations['customer-order-driver-assigned-email-information']['parameters']['query']
    >({
      'driver-id': String(driverId),
      'customer-order-id': String(orderId),
    }),
  })

export const orderApi = {
  getOrderList,
  getOrderMap,
  getCustomerOrderDetails,
  getOnlotInventoryOrderDetails,
  getInventoryMoveOrderDetails,
  getOnlotInventoryOrderDeliveryDetails,
  getInventoryMoveOrderDeliveryDetails,
  getCustomerOrderDeliveryDetails,
  getRtoOrderDeliveredEmailInformation,
  getOfficeAdminInventoryMoveOrderDeliveredEmailInformation,
  getCustomerOrderScheduledEmailInformation,
  getDriverReassignedOnlotInventoryOrderEmailInformation,
  getDriverAssignedOnlotInventoryOrderEmailInformation,
  getDriverReassignedInventoryMoveOrderEmailInformation,
  getDriverAssignedInventoryMoveOrderEmailInformation,
  getOfficeAdminOnlotInventoryOrderDeliveredEmailInformation,
  getCustomerOrderDriverReassignedEmailInformation,
  getSavedCustomerOrderChecks,
  getCustomerOrderDriverAssignedEmailInformation,
}

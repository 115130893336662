import { DeliveryDetails } from '../../../db/models/DeliveryDetails'
import { Emails, ImageType, OrderType } from '../../enums'
import { upload } from '../../helpers/upload'
import { clearDeliveryDetails } from '../helpers/clearDeliveryDetails'
import { validateDeliverOnlotInventoryOrderMoveOrderRequiredFields as validateDeliverInventoryMoveOrderRequiredFields } from './validation/deliverInventoryMoveOrder'
import { transformBuildingImagesForDelivery } from '../helpers/transformBuildingImagesForDelivery'
import { requestTodeliverOnlotInventoryOrderMoveOrder as requestTodeliverInventoryMoveOrder } from './requests/deliverInventoryMoveOrder'
import { sendEmailNotification } from '../../helpers/email-notifications'

export async function deliverInventoryMoveOrder(deliveryDetails: DeliveryDetails) {
  const inventoryMoveOrderId = deliveryDetails.inventoryMoveOrderId
  validateDeliverInventoryMoveOrderRequiredFields(deliveryDetails)

  const buildingImagesUploadResponse = await upload({
    base64Urls: deliveryDetails.uploadDataUrls,
    imageType: ImageType.BuildingImage,
    inventoryMoveOrderId,
  })

  const deliveryResponse = await requestTodeliverInventoryMoveOrder({
    inventoryMoveOrderId: deliveryDetails.inventoryMoveOrderId,
    dateDelivered: deliveryDetails.dateDelivered,
    deliveryMilesDriven: deliveryDetails.deliveryMilesDriven,
    deliveryGpsLat: deliveryDetails.deliveryGpsLat || 0,
    deliveryGpsLon: deliveryDetails.deliveryGpsLon || 0,
    deliveryBegan: deliveryDetails.deliveryBegan,
    deliveryEnded: deliveryDetails.deliveryEnded,
    deliveryNote: deliveryDetails.deliveryNote || '',
    buildingImages: transformBuildingImagesForDelivery(buildingImagesUploadResponse),
  })

  try {
    sendEmailNotification({
      type: Emails.ShedSuiteOfficeAdminOrderDelivered,
      orderId: inventoryMoveOrderId,
      orderType: OrderType.MoveOrder,
    })
  } catch (error) {
    console.error('Failed to send emails: ', error)
  }

  clearDeliveryDetails(deliveryDetails)

  return deliveryResponse
}

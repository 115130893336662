import { useSnackbar } from 'notistack'
import { ErrorMessages } from '../../context/enums'

export type SnackbarMessageType = string | Error

const formatMessageForSnackBar = (message: SnackbarMessageType) => {
    if (message instanceof Error) {
        return message.message
    } else if (typeof message === 'string') {
        return message
    } else {
        console.error('Invalid snackbar message type')
        return ErrorMessages.GeneralError
    }
}

export const useSnackBars = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    function errorSnackBar(message: SnackbarMessageType) {
        enqueueSnackbar(formatMessageForSnackBar(message), { variant: 'error' })
    }

    function warningSnackBar(message: SnackbarMessageType) {
        enqueueSnackbar(formatMessageForSnackBar(message), { variant: 'warning' })
    }

    function infoSnackBar(message: SnackbarMessageType) {
        enqueueSnackbar(formatMessageForSnackBar(message), { variant: 'info' })
    }

    function successSnackBar(message: SnackbarMessageType) {
        enqueueSnackbar(formatMessageForSnackBar(message), { variant: 'success' })
    }

    function defaultSnackBar(message: SnackbarMessageType) {
        enqueueSnackbar(formatMessageForSnackBar(message), { variant: 'default' })
    }

    return {
        errorSnackBar,
        warningSnackBar,
        infoSnackBar,
        successSnackBar,
        defaultSnackBar,
        closeSnackbar
    }
}

import { match } from 'ts-pattern'
import { EmailNotificationInput } from '.'
import { Logos, OrderType } from '../../enums'
import { sendEmail } from './index'
import { TE, pipe } from '../../../common/helpers/fp-ts'
import { orderApi } from '../../../api/order'

function sendDriverAssignedCustomerOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getCustomerOrderDriverAssignedEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'New Order Assigned',
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: true,
            tags: ['Driver - order assigned'],
            message: `Hello ${d.first_name},<br/>
                a customer order has been assigned to you!<br/>
                Name: ${d.customer_name}<br/>
                Delivery Address: ${d.delivery_address}<br/>
                Model and Size: ${d.building_model}<br/>
                Serial Number: ${d.serial_number}<br/>
                Order Number: ${d.order_number}<br/>
                Log in to your <a href="https://driver-app.shedsuite.com/">Shed Suite Account</a> to
                process this order! <br/>
                <br/>
                <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

function sendDriverAssignedInventoryMoveOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getDriverAssignedInventoryMoveOrderEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'New Order Assigned',
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: true,
            tags: ['Driver - order assigned'],
            message: `Hello ${d.first_name},<br/>
                  an inventory move order has been assigned to you!<br/>
                  Name: ${d.dealership_name}<br/>
                  Delivery Address: ${d.delivery_address}<br/>
                  Model and Size: ${d.building_model}<br/>
                  Serial Number: ${d.serial_number}<br/>
                  Order Number: ${d.order_number}<br/>
                  Log in to your <a href="https://driver-app.shedsuite.com/">Shed Suite Account</a> to
                  process this order! <br/>
                  <br/>
                  <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

function sendDriverAssignedOnlotInventoryOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getDriverAssignedOnlotInventoryOrderEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'New Order Assigned',
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: true,
            tags: ['Driver - order assigned'],
            message: `Hello ${d.first_name},<br/>
                  an inventory order has been assigned to you!<br/>
                  Name: ${d.dealership_name}<br/>
                  Delivery Address: ${d.delivery_address}<br/>
                  Model and Size: ${d.building_model}<br/>
                  Serial Number: ${d.serial_number}<br/>
                  Order Number: ${d.order_number}<br/>
                  Log in to your <a href="https://driver-app.shedsuite.com/">Shed Suite Account</a> to
                  process this order! <br/>
                  <br/>
                  <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

export async function sendDriverAssignedEmail(input: EmailNotificationInput) {
  return match(input.orderType)
    .with(OrderType.MoveOrder, () =>
      pipe(
        sendDriverAssignedInventoryMoveOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver assigned to inventory move order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver assigned to inventory move order email')
        )
      )()
    )
    .with(OrderType.Customer, () =>
      pipe(
        sendDriverAssignedCustomerOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver assigned to customer order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver assigned to customer order email')
        )
      )()
    )
    .with(OrderType.Inventory, () =>
      pipe(
        sendDriverAssignedOnlotInventoryOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver assigned to on-lot inventory order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver assigned to on-lot inventory order email')
        )
      )()
    )
    .exhaustive()
}

import { ErrorMessages, CommandEndPoints } from '../../../enums'
import { RequestTodeliverOnlotInventoryOrderNewParameters } from '../../../interfaces'
import { fetchApi } from '../../../helpers/fetchApi'

export async function requestTodeliverOnlotInventoryOrderNew({
  onlotInventoryOrderId,
  dateDelivered,
  deliveryMilesDriven,
  deliveryGpsLat,
  deliveryGpsLon,
  deliveryBegan,
  deliveryEnded,
  deliveryNote,
  buildingImages,
}: RequestTodeliverOnlotInventoryOrderNewParameters) {
  try {
    return await fetchApi({
      path: CommandEndPoints.DeliverOnlotInventoryOrderNew,
      body: {
        onlot_inventory_order_id: onlotInventoryOrderId,
        date_delivered: dateDelivered,
        delivery_miles_driven: deliveryMilesDriven,
        delivery_gps_lat: deliveryGpsLat,
        delivery_gps_lon: deliveryGpsLon,
        delivery_began: deliveryBegan,
        delivery_ended: deliveryEnded,
        delivery_note: deliveryNote,
        building_images: buildingImages,
      },
    })
  } catch (error) {
    console.error(`deliverOnlotInventoryOrder: ${error}`)
    throw new Error(ErrorMessages.FailedToDeliverInventoryOrder)
  }
}

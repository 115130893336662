import { EmailNotificationInput } from '.'
import { CommandEndPoints, ErrorMessages } from '../../enums'
import { fetchApi } from '../fetchApi'

export async function generateDeliveryCertificate(input: Pick<EmailNotificationInput, 'orderId'>) {
  const deliveryCertificateUrl = await fetchApi({
    path: CommandEndPoints.GenerateDeliveryCertificate,
    body: {
      customer_order_id: input.orderId,
      use_report2: false,
    },
  })

  if (!deliveryCertificateUrl || deliveryCertificateUrl.includes('ERROR:')) {
    console.error(ErrorMessages.FailedToGenerateDeliveryCertificate)
  }

  return deliveryCertificateUrl
}

import { match } from 'ts-pattern'
import { EmailNotificationInput } from '.'
import { Logos, OrderType, QueryEndPoints } from '../../enums'
import { fetchApi } from '../fetchApi'
import { sendEmail } from './index'
import { TE, pipe } from '../../../common/helpers/fp-ts'
import { orderApi } from '../../../api/order'

function sendOfficeAdminInventoryMoveOrderDeliveredEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getOfficeAdminInventoryMoveOrderDeliveredEmailInformation(input.orderId),
    TE.chain(dd =>
      TE.sequenceArray(
        dd
          .filter(d => !!d.send_email)
          .map(d =>
            TE.tryCatch(
              async () =>
                await sendEmail({
                  subject: `${d.driver_name} just completed a delivery`,
                  messageIsHtml: true,
                  recipient: d.email,
                  fromNoReply: true,
                  tags: ['Office Admin - order delivered'],
                  message: `
                    Hi ${d.admin_name}, <br/>
                    ${d.driver_name} just delivered a
                    ${d.building_model}, serial# ${d.serial_number}, ${d.miles_driven} miles to
                    ${d.dealer_name} at ${d.dealer_address}.
                    <br/>
                    Login to your <a href="https://app.shedsuite.com/?orderId=
                    ${d.inventory_move_order_id}&orderType=${d.order_type}">
                    Shed Suite account</a> to view this order.<br/>
                    <br/>
                    <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />
                `,
                }),
              () => new Error(`Failed to send email`)
            )
          )
      )
    ),
    TE.chain(dd =>
      dd.every(d => d.success)
        ? TE.right(true)
        : TE.left(new Error('Failed to send one or more emails'))
    )
  )
}

function sendOfficeAdminInventoryOrderDeliveredEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getOfficeAdminOnlotInventoryOrderDeliveredEmailInformation(input.orderId),
    TE.chain(dd =>
      TE.sequenceArray(
        dd
          .filter(d => !!d.send_email)
          .map(d =>
            TE.tryCatch(
              () =>
                sendEmail({
                  subject: `${d.assigned_driver_name} just completed a delivery`,
                  messageIsHtml: true,
                  recipient: d.email,
                  fromNoReply: true,
                  tags: ['Office Admin - order delivered'],
                  message: `
                Hi ${d.admin_name}, <br/>
                ${d.assigned_driver_name} just delivered a
                ${d.building_model}, serial# ${d.serial_number}, ${d.miles_driven} miles to
                ${d.dealership_name} at ${d.dealer_address}.
                <br/>
                Login to your <a href="https://app.shedsuite.com/?orderId=${d.onlot_inventory_order_id}&orderType=${d.order_type}"> Shed Suite account</a> to view this order.<br/>
                <br/>
                <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />
            `,
                }),
              () => new Error(`Failed to send email`)
            )
          )
      )
    ),
    TE.chain(dd =>
      dd.every(d => d.success)
        ? TE.right(true)
        : TE.left(new Error('Failed to send one or more emails'))
    )
  )
}

async function sendOfficeAdminCustomerOrderDeliveredEmail(input: EmailNotificationInput) {
  const emailInformation: any[] = await fetchApi({
    path: QueryEndPoints.EmailOfficeAdminCustomerOrderDelivered,
    body: {
      customer_order_id: input.orderId,
    },
  })

  return Promise.all(
    emailInformation.map((info: any) =>
      sendEmail({
        subject: `${info.driverName} just completed a delivery`,
        messageIsHtml: true,
        recipient: info.email,
        fromNoReply: true,
        tags: ['Office Admin - order delivered'],
        message: `
          Hi ${info.adminName}, <br/>
          ${info.driverName} just delivered a
          ${info.sizeAndModel}, serial# ${info.serialNumber}, ${info.miles} miles to
          ${info.customerName} at ${info.customerDeliveryAddress}.
          <br/>
          Login to your <a href="https://app.shedsuite.com/?orderId=
          ${info.orderId}&orderType=${info.orderType}">
          Shed Suite account</a> to view this order.<br/>
          <br/>
          <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />
      `,
      })
    )
  ).then(d => d.every(dd => !!dd.success))
}

export async function sendOfficeAdminOrderDeliveredEmail(input: EmailNotificationInput) {
  return await match(input.orderType)
    .with(OrderType.Customer, async () => await sendOfficeAdminCustomerOrderDeliveredEmail(input))
    .with(OrderType.MoveOrder, () =>
      pipe(
        sendOfficeAdminInventoryMoveOrderDeliveredEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send inventory move order delivered email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent inventory move order delivered email')
        )
      )()
    )
    .with(OrderType.Inventory, () =>
      pipe(
        sendOfficeAdminInventoryOrderDeliveredEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send office admin inventory order deliverd emails\n Error: ${error.message}`
            ),
          () => console.log('Successfully office admin inventory order delivered emails sent')
        )
      )()
    )
    .exhaustive()
}

import { ImageType, ErrorMessages, GcsBucketOrigin } from '../../../enums'

export function getGcsBucket({
  imageType,
  customerOrderId,
  onlotOrderInventoryOrderId,
  inventoryMoveOrderId,
}: {
  imageType: ImageType
  customerOrderId?: number
  onlotOrderInventoryOrderId?: number
  inventoryMoveOrderId?: number
}) {
  let bucketOrigin: string
  let gcsBucket: string

  if (!!customerOrderId || !!onlotOrderInventoryOrderId || !!inventoryMoveOrderId) {
    bucketOrigin = !!customerOrderId
      ? GcsBucketOrigin.DeliverCustomerOrder
      : GcsBucketOrigin.DeliverOnlotInventoryOrder
  } else if (imageType === ImageType.ProfileImage) {
    bucketOrigin = GcsBucketOrigin.ProfilePictures
  } else {
    throw new Error(ErrorMessages.NoOrderIdFound)
  }

  if (imageType === ImageType.BuildingImage) {
    gcsBucket = `${bucketOrigin}/delivery`
  } else if (imageType === ImageType.Signature && !!customerOrderId) {
    gcsBucket = `${bucketOrigin}/signature`
  } else if (imageType === ImageType.Check && !!customerOrderId) {
    gcsBucket = `${bucketOrigin}/check`
  } else if (imageType === ImageType.ProfileImage) {
    gcsBucket = bucketOrigin
  } else {
    throw new Error(ErrorMessages.BucketToUploadPhoto)
  }

  return gcsBucket
}

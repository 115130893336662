import { ImageType, ErrorMessages } from '../../../enums'

export async function constructFileUploadFormData({
  base64Urls,
  imageType,
}: {
  base64Urls: string[]
  imageType: ImageType
}) {
  let formData = new FormData()

  for (let i = 0; i < base64Urls.length; i++) {
    const base64Url = base64Urls[i]
    const promise = await fetch(base64Url)
    const blob = await promise.blob()
    let originalFileExtension = blob.type.split('/')[1]

    if (
      originalFileExtension === 'jpg' ||
      originalFileExtension === 'jpeg' ||
      originalFileExtension === 'JPG'
    ) {
      originalFileExtension = 'jpeg'
    } else if (originalFileExtension === 'png') {
      originalFileExtension = 'png'
    } else {
      throw new Error(ErrorMessages.WrongImageExtension)
    }

    formData.append(
      `file-${i}`,
      blob,
      `${imageType
        .trim()
        .toLowerCase()
        .replace(' ', '-')}-${i}.${originalFileExtension}`
    )

    if (i === base64Urls.length - 1) {
      return formData
    }
  }
}

import { match } from 'ts-pattern'
import { TE, pipe } from './fp-ts'
import { safeFetch } from '../../context/helpers/fetchApi'
import { FeatureFlag, QueryEndPoints, Service } from '../../context/enums'
import { constructUrlSearchParams } from '../../context/helpers/url'
import { getJWT } from './jwt'
import { environment } from './environment'

type CheckFeatureFlagResponse = {
  turned_on: boolean
}

export const checkFeatureFlag = (flagName: FeatureFlag) =>
  pipe(
    safeFetch<CheckFeatureFlagResponse>({
      method: 'GET',
      endpoint: QueryEndPoints.CheckFeatureFlag,
      service: Service.REMIX,
      urlSearchParams: constructUrlSearchParams({
        flag_name: flagName,
        token: getJWT(),
        environment: match(environment)
          .with('Production', () => 'production')
          .with('Master', () => 'master')
          .with('Development', () => 'branch')
          .exhaustive(),
      }),
    }),
    TE.map(({ turned_on }) => turned_on === true)
  )

import { FC, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import useNetwork from './common/hooks/UseNetwork'
import {
  deliver,
  useDeliveryDetailsDispatch,
  getOrdersThatAreReadyToDeliver,
} from './context/delivery-details'
import { QueryKeys } from './context/enums'

interface UploaderProps {}

const Uploader: FC<UploaderProps> = props => {
  const isConnected = useNetwork()
  const queryClient = useQueryClient()
  const deliveryDetailsDispatch = useDeliveryDetailsDispatch()

  const upload = useCallback(async () => {
    getOrdersThatAreReadyToDeliver().then(orders => {
      orders?.forEach(async deliveryDetails => {
        if (!!deliveryDetails.readyToDeliver && isConnected) {
          try {
            await deliver({ dispatch: deliveryDetailsDispatch, deliveryDetails })
            queryClient.invalidateQueries([QueryKeys.OrderList])
          } catch (error) {
            queryClient.invalidateQueries([QueryKeys.OrderList])
          }
        }
      })
    })
  }, [deliveryDetailsDispatch, isConnected, queryClient])

  useEffect(() => {
    try {
      if (!!isConnected) {
        upload()
      }
    } catch (e) {
      console.log(e)
    }
  }, [isConnected, upload])

  return <>{props.children}</>
}

export default Uploader

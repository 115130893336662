import { DeliveryDetails } from '../../../../db/models/DeliveryDetails'
import { ErrorMessages } from '../../../enums'

export function validateDeliverCustomerOrderRtoRequiredFeilds(details: DeliveryDetails) {
  if (!details.uploadDataUrls) {
    throw new Error(ErrorMessages.DeliveryPhotoRequired)
  } else if (!details.deliveryMilesDriven) {
    throw new Error(ErrorMessages.DeliveryMilesRequired)
  } else if (!details.signatureDataUrl) {
    throw new Error(ErrorMessages.DeliverySignatureRequired)
  }
}

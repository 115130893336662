import { DeliveryDetails } from '../../../../db/models/DeliveryDetails'
import { ErrorMessages } from '../../../enums'

export function validateDeliverCustomerOrderCashRequiredFields(deliveryDetails: DeliveryDetails) {
  if (!deliveryDetails.uploadDataUrls) {
    throw new Error(ErrorMessages.DeliveryPhotoRequired)
  } else if (!deliveryDetails.deliveryMilesDriven) {
    throw new Error(ErrorMessages.DeliveryMilesRequired)
  } else if (!deliveryDetails.signatureDataUrl) {
    throw new Error(ErrorMessages.DeliverySignatureRequired)
  }
}

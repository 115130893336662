import { ErrorMessages, CommandEndPoints } from '../../../enums'
import { RequestTodeliverInventoryMoveOrderParameters } from '../../../interfaces'
import { fetchApi } from '../../../helpers/fetchApi'

export async function requestTodeliverOnlotInventoryOrderMoveOrder({
  inventoryMoveOrderId,
  dateDelivered,
  deliveryMilesDriven,
  deliveryGpsLat,
  deliveryGpsLon,
  deliveryBegan,
  deliveryEnded,
  deliveryNote,
  buildingImages,
}: RequestTodeliverInventoryMoveOrderParameters) {
  try {
    return await fetchApi({
      path: CommandEndPoints.DeliverInventoryMoveOrder,
      body: {
        inventory_move_order_id: inventoryMoveOrderId,
        date_delivered: dateDelivered,
        delivery_miles_driven: deliveryMilesDriven,
        delivery_gps_lat: deliveryGpsLat,
        delivery_gps_lon: deliveryGpsLon,
        delivery_began: deliveryBegan,
        delivery_ended: deliveryEnded,
        delivery_note: deliveryNote,
        building_images: buildingImages,
      },
    })
  } catch (error) {
    console.error(`requestTodeliverOnlotInventoryOrderMoveOrder: ${error}`)
    throw new Error(ErrorMessages.FailedToDeliverInventoryMoveOrder)
  }
}

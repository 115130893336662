import { CircularProgress, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            marginTop: theme.spacing(4),
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
    })
)

interface CircularLoaderProps {
    styles?: any
    size?: number
}

export const CircularLoader = (p: CircularLoaderProps) => {
    const classes = useStyles()
    const { styles, size } = p

    return (
        <div className={styles || classes.spinner}>
            <CircularProgress size={size} />
        </div>
    )
}
import { db } from '../index'
import { populate } from './populate'

export function resetIndexedDBDatabase() {
  return db.transaction('rw', db.tables, async () => {
    await Promise.all(db.tables.map(table => table.clear()))
      .then(() => console.log('RESET INDEXEDDB DATABASE SUCCESS'))
      .catch(() => console.error('RESET INDEXEDDB DATABASE FAILED'))
    await populate()
  })
}

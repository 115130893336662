import { A, TE, pipe } from '../../common/helpers/fp-ts'
import { CommandEndPoints, QueryEndPoints, Service } from '../enums'
import { isProblemDetails, createError, safeFetch } from '../helpers/fetchApi'
import { createPaymentClient } from './payment-client'
import { ProblemDetails } from '@shedsuite-gitlab/payments'

type PaymentProviderStatus = 'Approved' | 'Retry' | 'Declined'

type PaymentClient = ReturnType<typeof createPaymentClient>

type PaymentsV2AuthNewCardResponse = Exclude<
  Awaited<ReturnType<PaymentClient['auth_new_card']>>,
  ProblemDetails
>

type PaymentsV2FetchAllSavedCreditCardsResponse = Exclude<
  Awaited<ReturnType<PaymentClient['fetch_saved_card_details']>>,
  ProblemDetails
>

type AuthenticateNewCardInput = {
  customerOrderId: number
  cardCvv: string
  cardExpMonth: string
  cardExpYear: string
  paymentAmount: string
  accountToken: string
  customerFirstName: string
  customerLastName: string
  billingAddress: string
  billingCity: string
  billingState: string
  billingZip: string
  billingPhone: string
  billingEmail: string
  orderNumber: string
}

export type AuthNewCardResponse = {
  profileId: string
  result: PaymentProviderStatus
  firstFourDigits: string
  retref: string
  cardBrand: string
}

type SavedCreditCardResponse = {
  accttype: string
  expiry: string
  profileid: string
  token: string
}

class PaymentsError extends Error {
  readonly _tag = 'PaymentsError' as const
}

const authNewCardResponseAdapter = (input: PaymentsV2AuthNewCardResponse): AuthNewCardResponse => ({
  cardBrand: input.card_brand,
  firstFourDigits: input.first_four_digits,
  profileId: input.profile_id,
  result: input.status,
  retref: input.transaction_id,
})

const fetchAllSavedCreditCardsDetailsResponseAdapter = (
  input: PaymentsV2FetchAllSavedCreditCardsResponse[]
): SavedCreditCardResponse[] =>
  input.flat().map(cc => ({
    accttype: cc.card_brand,
    expiry: cc.expiration,
    profileid: cc.profile_id,
    token: cc.account_number,
  }))

const authNewCard_CardConnectService = (input: AuthenticateNewCardInput) =>
  safeFetch<AuthNewCardResponse>({
    endpoint: CommandEndPoints.AuthNewCard,
    service: Service.CARD_CONNECT,
    method: 'POST',
    body: {
      account_token: input.accountToken,
      payment_amount: input.paymentAmount,
      card_expiration: [input.cardExpMonth, input.cardExpYear].map(v => v?.trim()).join(''),
      name_on_card: [input.customerFirstName, input.customerLastName].map(v => v.trim()).join(' '),
      billing_address: input.billingAddress,
      billing_city: input.billingCity,
      billing_state: input.billingState,
      billing_zip: input.billingZip,
      billing_phone: input.billingPhone,
      billing_email: input.billingEmail,
      cvv: input.cardCvv,
      order_number: input.orderNumber,
    },
  })

const authNewCard_PaymentsV2 = (input: AuthenticateNewCardInput) =>
  pipe(
    TE.tryCatch(async () => {
      const paymentClient = createPaymentClient()

      const result = await paymentClient.auth_new_card({
        account_token: input.accountToken,
        expiration: [input.cardExpMonth, input.cardExpYear].map(v => v.trim()).join(''),
        amount: input.paymentAmount,
        first_name: input.customerFirstName.trim(),
        last_name: input.customerLastName.trim(),
        billing_address: input.billingAddress,
        billing_city: input.billingCity,
        billing_state: input.billingState,
        billing_email: input.billingEmail,
        billing_phone: input.billingPhone,
        billing_zip: input.billingZip,
        cvv: input.cardCvv,
        order_number: input.orderNumber,
      })

      if (isProblemDetails(result)) {
        throw result.details
      }

      return result
    }, createError),
    TE.map(authNewCardResponseAdapter)
  )

const fetchAllSavedCreditCardsDetails_CardConnectService = ({
  profileIds,
}: {
  profileIds: string[]
}) =>
  pipe(
    profileIds,
    TE.traverseArray(profileId =>
      safeFetch<SavedCreditCardResponse[]>({
        endpoint: QueryEndPoints.ProfileInquery,
        service: Service.CARD_CONNECT,
        method: 'POST',
        body: {
          profileId,
        },
      })
    ),
    TE.map(A.flatten)
  )

const fetchAllSavedCreditCardsDetails_PaymentsV2 = ({ profileIds }: { profileIds: string[] }) =>
  pipe(
    TE.tryCatch(
      async () => {
        const paymentClient = createPaymentClient()

        return await Promise.all(
          profileIds.map(profileId =>
            paymentClient.fetch_saved_card_details({ profile_id: profileId })
          )
        ).then(
          A.map(response => {
            if (isProblemDetails(response)) {
              throw response.details
            }

            return response
          })
        )
      },
      error => new Error(String(error))
    ),
    TE.map(fetchAllSavedCreditCardsDetailsResponseAdapter)
  )

export const authenticateNewCard = (input: AuthenticateNewCardInput & { payments_v2: boolean }) =>
  pipe(
    input.payments_v2 ? authNewCard_PaymentsV2(input) : authNewCard_CardConnectService(input),
    TE.mapLeft(error => new PaymentsError(error.message))
  )

export const fetchAllSavedCreditCardsDetails = (input: {
  profileIds: string[]
  payments_v2: boolean
}) =>
  pipe(
    input.payments_v2
      ? fetchAllSavedCreditCardsDetails_PaymentsV2(input)
      : fetchAllSavedCreditCardsDetails_CardConnectService(input),
    TE.mapLeft(error => new PaymentsError(error.message))
  )

import { DeliveryDetails } from '../../../db/models/DeliveryDetails'
import { ImageType, Emails, OrderType } from '../../enums'
import { sendEmailNotification } from '../../helpers/email-notifications'
import { upload } from '../../helpers/upload'
import { clearDeliveryDetails } from '../helpers/clearDeliveryDetails'
import { validateDeliverOnlotInventoryOrderNewRequiredFields } from './validation/deliverOnlotInventoryOrderNew'
import { requestTodeliverOnlotInventoryOrderNew } from './requests/deliverOnlotInventoryOrderNew'
import { transformBuildingImagesForDelivery } from '../helpers/transformBuildingImagesForDelivery'

export async function deliverOnlotInventoryOrderNew(deliveryDetails: DeliveryDetails) {
  const onlotOrderInventoryOrderId = deliveryDetails.onlotInventoryOrderId
  validateDeliverOnlotInventoryOrderNewRequiredFields(deliveryDetails)

  const buildingImagesUploadResponse = await upload({
    base64Urls: deliveryDetails.uploadDataUrls,
    imageType: ImageType.BuildingImage,
    onlotOrderInventoryOrderId,
  })

  const deliveryResponse = await requestTodeliverOnlotInventoryOrderNew({
    onlotInventoryOrderId: onlotOrderInventoryOrderId,
    dateDelivered: deliveryDetails.dateDelivered,
    deliveryMilesDriven: deliveryDetails.deliveryMilesDriven,
    deliveryGpsLat: deliveryDetails.deliveryGpsLat || 0,
    deliveryGpsLon: deliveryDetails.deliveryGpsLon || 0,
    deliveryBegan: deliveryDetails.deliveryBegan,
    deliveryEnded: deliveryDetails.deliveryEnded,
    deliveryNote: deliveryDetails.deliveryNote || '',
    buildingImages: transformBuildingImagesForDelivery(buildingImagesUploadResponse),
  })

  try {
    sendEmailNotification({
      type: Emails.ShedSuiteOfficeAdminOrderDelivered,
      orderId: onlotOrderInventoryOrderId,
      orderType: OrderType.Inventory,
    })
  } catch (error) {
    console.error('Failed to send emails: ', error)
  }

  clearDeliveryDetails(deliveryDetails)

  return deliveryResponse
}

import { createContext, FC, useContext, useEffect, useReducer } from 'react'
import { formatISO } from 'date-fns'
import { isEmpty } from 'lodash'
import { useQuery } from 'react-query'
import { DeliveryDetails } from '../../db/models/DeliveryDetails'
import {
  CardSelection,
  ErrorMessages,
  FeatureFlag,
  LoadingStatus,
  OrderType,
  PaymentMethod,
  QueryKeys,
} from '../enums'
import { useSnackBars } from '../../common/hooks/useSnackBars'
import useOrderIdFromUrl from '../../common/hooks/UseOrderIdFromUrl'
import { useOrderType } from '../../pages/delivery-details/hooks/useOrderType'
import { getJWT } from '../../common/helpers/jwt'
import { isConnected } from '../helpers/isConnected'
import { OrderListItem } from '../../db/models/OrderList'
import { db } from '../../db'
import { delay } from '../../common/helpers/delay'
import { storeDeliveryDetails } from './helpers/storeDeliveryDetails'
import { deliverOnlotInventoryOrderNew } from './deliver/deliverOnlotInventoryOrderNew'
import { deliverInventoryMoveOrder } from './deliver/deliverInventoryMoveOrder'
import { deliverCustomerOrderRTO } from './deliver/deliverCustomerOrderRto'
import { deliverCustomerOrderNewCheck } from './deliver/deliverCustomerOrderNewCheck'
import { deliverCustomerOrderNoPaymentRequired } from './deliver/deliverCustomerOrderNoPaymentRequired'
import { CheckSelection } from '../../db/enums/deliveryDetails'
import { AuthNewCardResponse } from './credit-card'
import { deliverCustomerOrderSavedCheck } from './deliver/deliverCustomerOrderSavedCheck'
import { deliverCustomerOrderCash } from './deliver/deliverCustomerOrderCash'
import { deliverCustomerOrderExistingCard } from './deliver/deliverCustomerOrderExistingCard'
import { deliverCustomerOrderNewCard } from './deliver/deliverCustomerOrderNewCard'
import { checkFeatureFlag } from '../../common/helpers/featureFlags'
import { pipe, TE } from '../../common/helpers/fp-ts'
import { orderApi } from '../../api/order'

export type DeliveryDetailsState = {
  deliveryDetailsLoadingStatus: LoadingStatus
  deliverOrderLoadingStatus: LoadingStatus
  deliveryError: string
  deliveryImageToDeleteIndex: number
  deliveryDetailsInitalized: boolean
} & DeliveryDetails

type DeliveryDetailsProviderProps = {}

type ActionTypes =
  | 'DELIVERY_DETAILS_FETCH_START'
  | 'DELIVERY_DETAILS_FETCH_FAIL'
  | 'DELIVERY_DETAILS_FETCH_SUCCESS'
  | 'AUTHENTICATE_NEW_CARD_FAIL'
  | 'DELIVER_ORDER_START'
  | 'DELIVER_ORDER_FAIL'
  | 'DELIVER_ORDER_SUCCESS'
  | 'INITIALIZE_STATE'
  | 'NOTE'
  | 'ADD_DELIVERY_IMAGES'
  | 'SIGNATURE_IMAGE'
  | 'CHECK_IMAGE'
  | 'CHECK_SELECTION'
  | 'TOKENIZED_CARD_NUMBER'
  | 'CARD_EXP'
  | 'CARD_CVV'
  | 'MILES_DRIVEN'
  | 'DELIVERY_TIME'
  | 'CARD_PROFILE_ID'
  | 'NEW_CARD_AUTHENTICATED'
  | 'NEW_CARD_FIRST_FOUR_DIGITS'
  | 'NEW_CARD_RETREF'
  | 'PAYMENT_METHOD'
  | 'PAYMENT_AMOUNT'
  | 'CARD_SELECTION'
  | 'HAPPY_LIST'
  | 'READY_TO_DELIVER'
  | 'LOCATION'
  | 'DELETE_DELIVERY_PHOTO'
  | 'SAVED_CHECK_ID'

export type DeliveryDetailsDispatch = ({
  type,
}: {
  type: ActionTypes
  value?: Partial<DeliveryDetailsState>
}) => void

const DeliveryDetailsStateContext = createContext<DeliveryDetailsState | undefined>(undefined)
const DeliveryDetailsDispatchContext = createContext<DeliveryDetailsDispatch | undefined>(undefined)

DeliveryDetailsStateContext.displayName = 'DeliveryDetailsStateContext'
DeliveryDetailsDispatchContext.displayName = 'DeliveryDetailsDispatchContext'

const DeliveryDetailsReducer = (
  state: DeliveryDetailsState,
  action: { type: ActionTypes; value?: Partial<DeliveryDetailsState> }
): DeliveryDetailsState => {
  const { type, value } = action

  switch (type) {
    case 'DELIVERY_DETAILS_FETCH_START':
      return { ...state, deliveryDetailsLoadingStatus: LoadingStatus.Pending }
    case 'DELIVERY_DETAILS_FETCH_SUCCESS':
      return { ...state, deliveryDetailsLoadingStatus: LoadingStatus.Resolved }
    case 'DELIVERY_DETAILS_FETCH_FAIL':
      return {
        ...state,
        deliveryDetailsLoadingStatus: LoadingStatus.Rejected,
        ...value,
      }
    case 'AUTHENTICATE_NEW_CARD_FAIL':
      return {
        ...state,
        ...value,
      }
    case 'DELIVER_ORDER_START':
      return { ...state, deliverOrderLoadingStatus: LoadingStatus.Pending }
    case 'DELIVER_ORDER_SUCCESS':
      return { ...state, deliverOrderLoadingStatus: LoadingStatus.Resolved }
    case 'DELIVER_ORDER_FAIL':
      return { ...state, deliverOrderLoadingStatus: LoadingStatus.Rejected }
    case 'INITIALIZE_STATE':
      return { ...state, ...value }
    case 'NOTE':
      return { ...state, deliveryNote: value.deliveryNote }
    case 'ADD_DELIVERY_IMAGES':
      return {
        ...state,
        uploadDataUrls: state.uploadDataUrls.concat(value.uploadDataUrls),
      }
    case 'SIGNATURE_IMAGE':
      return {
        ...state,
        signatureDataUrl: value.signatureDataUrl,
        signatureFrom: value.signatureFrom,
      }
    case 'CHECK_IMAGE':
      return { ...state, checkDataUrl: value.checkDataUrl }
    case 'TOKENIZED_CARD_NUMBER':
      return { ...state, cardToken: value.cardToken }
    case 'CARD_EXP':
      return {
        ...state,
        cardExpYear: value.cardExpYear,
        cardExpMonth: value.cardExpMonth,
      }
    case 'CARD_CVV':
      return { ...state, cardCvv: value.cardCvv }
    case 'MILES_DRIVEN':
      return { ...state, deliveryMilesDriven: +value.deliveryMilesDriven }
    case 'DELIVERY_TIME':
      return {
        ...state,
        deliveryBegan: formatISO(value.deliveryBegan),
        deliveryEnded: formatISO(value.deliveryEnded),
      }
    case 'CARD_PROFILE_ID':
      return { ...state, cardProfileId: value.cardProfileId }
    case 'NEW_CARD_AUTHENTICATED':
      return { ...state, newCardAuthenticated: value.newCardAuthenticated }
    case 'NEW_CARD_FIRST_FOUR_DIGITS':
      return {
        ...state,
        newCardFirstFourDigits: value.newCardFirstFourDigits,
      }
    case 'NEW_CARD_RETREF':
      return { ...state, retref: value.retref }
    case 'PAYMENT_METHOD':
      return { ...state, paymentMethod: value.paymentMethod }
    case 'PAYMENT_AMOUNT':
      return { ...state, paymentAmount: String(value.paymentAmount) }
    case 'CARD_SELECTION':
      return { ...state, cardSelection: value.cardSelection }
    case 'CHECK_SELECTION':
      return { ...state, checkSelection: value.checkSelection }
    case 'HAPPY_LIST':
      return { ...state, happyListResults: value.happyListResults }
    case 'READY_TO_DELIVER':
      return { ...state, readyToDeliver: value.readyToDeliver }
    case 'LOCATION':
      return {
        ...state,
        deliveryGpsLat: value.deliveryGpsLat,
        deliveryGpsLon: value.deliveryGpsLon,
      }
    case 'DELETE_DELIVERY_PHOTO':
      return {
        ...state,
        uploadDataUrls: state.uploadDataUrls.filter(
          (_, i) => i !== value.deliveryImageToDeleteIndex
        ),
      }
    case 'SAVED_CHECK_ID':
      return {
        ...state,
        customerOrderSavedCheckId: value.customerOrderSavedCheckId,
      }
    default: {
      throw new Error(`Unhandled action: ${action}`)
    }
  }
}

const DeliveryDetailsProvider: FC<DeliveryDetailsProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(DeliveryDetailsReducer, null)
  const { errorSnackBar } = useSnackBars()
  const { customerOrderId, onlotOrderId, moveOrderId } = useOrderIdFromUrl()
  const orderType = useOrderType()
  const { orderId } = useOrderIdFromUrl()
  const jwt = getJWT()

  const getDeliveryDetails = async () => {
    if (!orderId || !orderType) return {}

    switch (orderType) {
      case OrderType.Customer:
        return await refreshCustomerOrderDeliveryDetails({ customerOrderId, dispatch })
      case OrderType.MoveOrder:
        return await refreshInventoryMoveOrderDeliveryDetails({
          inventoryMoveOrderId: moveOrderId,
          dispatch,
        })
      case OrderType.Inventory:
        return await await refreshOnlotInventoryOrderDeliveryDetails({
          onlotInventoryOrderId: onlotOrderId,
          dispatch,
        })
      default:
        throw new Error(ErrorMessages.FailedToFetchDeliveryDetails)
    }
  }

  const { data: deliveryDetails } = useQuery(
    [QueryKeys.DeliveryDetails, orderId, orderType],
    async () => {
      if (jwt) return await getDeliveryDetails()
    },
    {
      onError: errorSnackBar,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (deliveryDetails) {
      dispatch({
        type: 'INITIALIZE_STATE',
        value: {
          deliveryDetailsInitalized: true,
          deliveryDetailsLoadingStatus: LoadingStatus.Idle,
          deliverOrderLoadingStatus: LoadingStatus.Idle,
          inquiringProfilesLoadingStatus: LoadingStatus.Idle,
          deliveryError: undefined,
          deliveryImageToDeleteIndex: undefined,
          uploadDataUrls: [],
          ...deliveryDetails,
        },
      })
    }
  }, [deliveryDetails])

  return (
    <DeliveryDetailsStateContext.Provider value={state}>
      <DeliveryDetailsDispatchContext.Provider value={dispatch}>
        {children}
      </DeliveryDetailsDispatchContext.Provider>
    </DeliveryDetailsStateContext.Provider>
  )
}

const useDeliveryDetailsState = () => {
  const context = useContext(DeliveryDetailsStateContext)
  if (context === undefined) {
    throw new Error(`useDeliveryDetailsState must be used within a DeliveryDetailsProvider`)
  }
  return context
}

const useDeliveryDetailsDispatch = () => {
  const context = useContext(DeliveryDetailsDispatchContext)
  if (context === undefined) {
    throw new Error(`useDeliveryDetailsDispatch must be used within a DeliveryDetailsProvider`)
  }
  return context
}

type RefreshOnlotInventoryOrderDeliveryDetails = {
  dispatch: DeliveryDetailsDispatch
  onlotInventoryOrderId: number
}
const refreshOnlotInventoryOrderDeliveryDetails = async ({
  dispatch,
  onlotInventoryOrderId,
}: RefreshOnlotInventoryOrderDeliveryDetails) => {
  try {
    if (isConnected()) {
      const cachedDeliveryDetails = await findOrCreateDetailsForID({
        dispatch,
        onlotInventoryOrderId,
      })

      return await pipe(
        orderApi.getOnlotInventoryOrderDeliveryDetails(onlotInventoryOrderId),
        TE.map(delivery_details => {
          const retval: DeliveryDetails = {
            customer_first_name: null,
            customer_last_name: null,
            customer_phone: null,
            customer_email: null,
            order_number: null,
            billing_address: {
              street_address_one: null,
              street_address_two: null,
              city: null,
              state: null,
              zip: null,
            },
            readyToDeliver: false,
            isRto: false,
            happyListResults: [],
            paymentAmount: '0',
            deliveryGpsLat: 0,
            deliveryGpsLon: 0,
            buildingName: delivery_details.buildingName,
            serialNumber: delivery_details.serialNumber,
            nameOnOrder: delivery_details.dealershipName,
            ...cachedDeliveryDetails,
            deliveryBegan: formatISO(new Date()),
            deliveryEnded: formatISO(new Date()),
          }
          return retval
        }),
        TE.getOrElseW(e => {
          throw e
        })
      )()
    } else {
      return await findOrCreateDetailsForID({
        dispatch,
        onlotInventoryOrderId,
      })
    }
  } catch (error) {
    console.error(`Failed to refreshInventoryOrderDeliveryDetails: `, error)
    throw new Error(ErrorMessages.FailedToFetchDeliveryDetails)
  }
}

type RefreshInventoryMoveOrderDeliveryDetails = {
  dispatch: DeliveryDetailsDispatch
  inventoryMoveOrderId: number
}
const refreshInventoryMoveOrderDeliveryDetails = async ({
  dispatch,
  inventoryMoveOrderId,
}: RefreshInventoryMoveOrderDeliveryDetails) => {
  try {
    if (isConnected()) {
      const cachedDeliveryDetails = await findOrCreateDetailsForID({
        dispatch,
        inventoryMoveOrderId,
      })

      return await pipe(
        orderApi.getInventoryMoveOrderDeliveryDetails(inventoryMoveOrderId),
        TE.map(delivery_details => {
          const retval: DeliveryDetails = {
            customer_first_name: null,
            customer_last_name: null,
            customer_phone: null,
            customer_email: null,
            order_number: null,
            billing_address: {
              street_address_one: null,
              street_address_two: null,
              city: null,
              state: null,
              zip: null,
            },
            readyToDeliver: false,
            isRto: false,
            happyListResults: [],
            paymentAmount: '0',
            deliveryGpsLat: 0,
            deliveryGpsLon: 0,
            buildingName: delivery_details.buildingName,
            serialNumber: delivery_details.serialNumber,
            nameOnOrder: delivery_details.dealershipName,
            ...cachedDeliveryDetails,
            deliveryBegan: formatISO(new Date()),
            deliveryEnded: formatISO(new Date()),
          }
          return retval
        }),
        TE.getOrElseW(e => {
          throw e
        })
      )()
    } else {
      return await findOrCreateDetailsForID({
        dispatch,
        inventoryMoveOrderId,
      })
    }
  } catch (error) {
    console.error(`Failed to refreshInventoryMoveOrderDeliveryDetails: `, error)
    throw new Error(ErrorMessages.FailedToFetchDeliveryDetails)
  }
}

type RefreshCustomerOrderDeliveryDetails = {
  dispatch: DeliveryDetailsDispatch
  customerOrderId: number
}
const refreshCustomerOrderDeliveryDetails = async ({
  dispatch,
  customerOrderId,
}: RefreshCustomerOrderDeliveryDetails) => {
  try {
    if (isConnected()) {
      const cachedDeliveryDetails = await findOrCreateDetailsForID({
        dispatch,
        customerOrderId,
      })

      return await pipe(
        TE.Do,
        TE.apS('payments_v2', checkFeatureFlag(FeatureFlag.PAYMENTS_V2)),
        TE.apS('delivery_details', orderApi.getCustomerOrderDeliveryDetails(customerOrderId)),
        TE.map(({ payments_v2, delivery_details }) => {
          const billing_address = delivery_details.billing_address
          const retval: DeliveryDetails = {
            payments_v2: payments_v2,
            customer_first_name: delivery_details.customer_first_name,
            customer_last_name: delivery_details.customer_last_name,
            customer_phone: delivery_details.customer_phone,
            customer_email: delivery_details.customer_email,
            order_number: delivery_details.order_number,
            billing_address: {
              street_address_one: billing_address.street_address_one,
              street_address_two: billing_address.street_address_two,
              city: billing_address.city,
              state: billing_address.state,
              zip: billing_address.zip,
            },
            readyToDeliver: false,
            nameOnOrder: delivery_details.name_on_order,
            isRto: delivery_details.is_rto,
            serialNumber: delivery_details.serial_number,
            buildingName: delivery_details.building_name,
            deliveryGpsLat: delivery_details.delivery_address_coordinates.lat,
            deliveryGpsLon: delivery_details.delivery_address_coordinates.lon,
            ...cachedDeliveryDetails,
            paymentAmount: delivery_details.remaining_balance,
            remainingBalance: delivery_details.remaining_balance,
            happyListResults: delivery_details.happy_list_results.map(item => ({
              item,
              text: '',
              value: true,
            })),
            customerCardList: delivery_details.customer_card_list,
            deliveryBegan: formatISO(new Date()),
            deliveryEnded: formatISO(new Date()),
            paymentProcessorConfig: delivery_details.payment_processor_config,
            inventoryMoveOrderId: null,
          }
          return retval
        }),
        TE.getOrElseW(e => {
          throw e
        })
      )()
    } else {
      return await findOrCreateDetailsForID({
        dispatch,
        customerOrderId,
      })
    }
  } catch (error) {
    console.error(`Failed to refreshCustomerOrderDeliveryDetails: `, error)
    throw new Error(ErrorMessages.FailedToFetchDeliveryDetails)
  }
}

const preCacheDeliveryDetails = async (orderListItems: OrderListItem[]) => {
  const allCustomerOrderDeliveryDetails = await db.CustomerOrderDeliveryDetails.toArray()
  const allOnlotInventoryOrderDeliveryDetails =
    await db.OnlotInventoryOrderDeliveryDetails.toArray()
  const allInventoryMoveOrderDeliveryDetails = await db.OnlotInventoryOrderDeliveryDetails.toArray()
  const allOrderDetails = [
    ...allCustomerOrderDeliveryDetails,
    ...allOnlotInventoryOrderDeliveryDetails,
    ...allInventoryMoveOrderDeliveryDetails,
  ]
  let notFoundCount = 0

  for (const oli of orderListItems) {
    const { customer_order_id, onlot_inventory_order_id, inventory_move_order_id } = oli
    if (!customer_order_id && !onlot_inventory_order_id && !inventory_move_order_id) return

    const cachedDeliveryDetails = allOrderDetails.find(
      orderDetails =>
        orderDetails.inventoryMoveOrderId === +inventory_move_order_id ||
        orderDetails.customerOrderId === +customer_order_id ||
        orderDetails.onlotInventoryOrderId === +onlot_inventory_order_id
    )

    if (isEmpty(cachedDeliveryDetails)) {
      notFoundCount += 1
      if (notFoundCount % 10 === 0) {
        // prevents too many requests going off
        await delay(400)
      }

      if (!!customer_order_id) {
        const details = await refreshCustomerOrderDeliveryDetails({
          customerOrderId: +customer_order_id,
          dispatch: () => {},
        })
        storeDeliveryDetails(details)
      } else if (!!inventory_move_order_id) {
        const details = await refreshInventoryMoveOrderDeliveryDetails({
          inventoryMoveOrderId: +inventory_move_order_id,
          dispatch: () => {},
        })
        storeDeliveryDetails(details)
      } else if (!!onlot_inventory_order_id) {
        const details = await refreshOnlotInventoryOrderDeliveryDetails({
          onlotInventoryOrderId: +onlot_inventory_order_id,
          dispatch: () => {},
        })
        storeDeliveryDetails(details)
      }
    }
  }
}

type Deliver = {
  dispatch: DeliveryDetailsDispatch
  deliveryDetails: DeliveryDetails
}
const deliver = async ({ dispatch, deliveryDetails }: Deliver) => {
  dispatch({ type: 'DELIVER_ORDER_START' })

  try {
    deliveryDetails.readyToDeliver = true
    if (!deliveryDetails.dateDelivered) {
      deliveryDetails.dateDelivered = formatISO(new Date())
    }
    storeDeliveryDetails(deliveryDetails)
    const {
      isRto,
      inventoryMoveOrderId,
      onlotInventoryOrderId,
      customerOrderId,
      paymentMethod,
      cardSelection,
      checkSelection,
    } = deliveryDetails

    if (!!isRto) {
      return await deliverCustomerOrderRTO(deliveryDetails)
    } else if (!!inventoryMoveOrderId) {
      return await deliverInventoryMoveOrder(deliveryDetails)
    } else if (!!onlotInventoryOrderId) {
      return await deliverOnlotInventoryOrderNew(deliveryDetails)
    } else if (!!customerOrderId) {
      if (!paymentMethod) {
        throw new Error(ErrorMessages.PaymentMethodRequired)
      }

      switch (paymentMethod) {
        case PaymentMethod.Check: {
          if (checkSelection === CheckSelection.NewCheck) {
            return await deliverCustomerOrderNewCheck(deliveryDetails)
          } else if (checkSelection === CheckSelection.SavedCheck) {
            return await deliverCustomerOrderSavedCheck(deliveryDetails)
          } else {
            throw new Error(ErrorMessages.CheckSelectionTypeIsRequired)
          }
        }
        case PaymentMethod.NoPayment: {
          return await deliverCustomerOrderNoPaymentRequired(deliveryDetails)
        }
        case PaymentMethod.Cash: {
          return await deliverCustomerOrderCash(deliveryDetails)
        }
        case PaymentMethod.CreditCard:
          if (cardSelection === CardSelection.ExistingCard) {
            return await deliverCustomerOrderExistingCard(deliveryDetails)
          } else if (cardSelection === CardSelection.NewCard) {
            return await deliverCustomerOrderNewCard(deliveryDetails)
          } else {
            throw new Error(ErrorMessages.CardSelectionTypeIsRequired)
          }
        default: {
          throw new Error(ErrorMessages.PaymentMethodNotImplemented)
        }
      }
    }
  } catch (error) {
    console.error('deliver: ', error)
    dispatch({
      type: 'DELIVER_ORDER_FAIL',
      value: { deliveryError: error?.message || 'Error delivering order' },
    })
    throw error
  }
}

type FindOrCreateDetailsForID = {
  dispatch: DeliveryDetailsDispatch
  customerOrderId?: number
  onlotInventoryOrderId?: number
  inventoryMoveOrderId?: number
}
const findOrCreateDetailsForID = async ({
  dispatch,
  customerOrderId,
  onlotInventoryOrderId,
  inventoryMoveOrderId,
}: FindOrCreateDetailsForID) => {
  dispatch({ type: 'DELIVERY_DETAILS_FETCH_START' })
  try {
    let foundDeliveryDetails

    if (!!customerOrderId) {
      foundDeliveryDetails = await db.getCustomerOrderDeliveryDetails(customerOrderId)
    } else if (!!onlotInventoryOrderId) {
      foundDeliveryDetails = await db.getOnlotInventoryOrderDeliveryDetails(onlotInventoryOrderId)
    } else if (!!inventoryMoveOrderId) {
      foundDeliveryDetails = await db.getInventoryMoveOrderDeliveryDetails(inventoryMoveOrderId)
    } else {
      return
    }

    let details = {
      customerOrderId: customerOrderId,
      onlotInventoryOrderId: onlotInventoryOrderId,
      inventoryMoveOrderId: inventoryMoveOrderId,
    }

    if (!isEmpty(foundDeliveryDetails)) {
      dispatch({ type: 'DELIVERY_DETAILS_FETCH_SUCCESS' })
      return foundDeliveryDetails
    } else {
      storeDeliveryDetails(details)
      dispatch({ type: 'DELIVERY_DETAILS_FETCH_SUCCESS' })
      return details
    }
  } catch (error) {
    console.error(`findOrCreateDetailsForID: ${error}`)
    throw new Error(ErrorMessages.FailedToFindDeliveryDetails)
  }
}

const getOrdersThatAreReadyToDeliver = async () => await db.getOrdersThatAreReadyToDeliver()

const authenticateNewCardApproved = (
  dispatch: DeliveryDetailsDispatch,
  authNewCardResponse: AuthNewCardResponse
) => {
  dispatch({
    type: 'CARD_PROFILE_ID',
    value: {
      cardProfileId: authNewCardResponse.profileId,
    },
  })
  dispatch({
    type: 'NEW_CARD_FIRST_FOUR_DIGITS',
    value: {
      newCardFirstFourDigits: authNewCardResponse.firstFourDigits,
    },
  })
  dispatch({
    type: 'NEW_CARD_RETREF',
    value: {
      retref: authNewCardResponse.retref,
    },
  })
  dispatch({
    type: 'NEW_CARD_AUTHENTICATED',
    value: {
      newCardAuthenticated: true,
    },
  })
}

const authenticateNewCardFail = (dispatch: DeliveryDetailsDispatch) =>
  dispatch({
    type: 'NEW_CARD_AUTHENTICATED',
    value: {
      newCardAuthenticated: false,
    },
  })

const deleteDeliveryPhotoByIndex = (
  dispatch: DeliveryDetailsDispatch,
  deliveryImageToDeleteIndex: number
) => dispatch({ type: 'DELETE_DELIVERY_PHOTO', value: { deliveryImageToDeleteIndex } })

export { authenticateNewCard, fetchAllSavedCreditCardsDetails } from './credit-card'

export {
  DeliveryDetailsProvider,
  useDeliveryDetailsState,
  useDeliveryDetailsDispatch,
  findOrCreateDetailsForID,
  deliver,
  getOrdersThatAreReadyToDeliver,
  authenticateNewCardApproved,
  authenticateNewCardFail,
  deleteDeliveryPhotoByIndex,
  refreshCustomerOrderDeliveryDetails,
  preCacheDeliveryDetails,
  refreshInventoryMoveOrderDeliveryDetails,
}

import { reduce } from 'ramda'

type TSearchParams = {
  [key: string]: string | number | boolean | Date
}

export const constructUrlSearchParams = <T extends TSearchParams>(searchParams: T) =>
  new URLSearchParams(
    reduce(
      (acc, [key, value]) => {
        if (!value) return acc

        if (typeof value === 'string') {
          acc[key] = value
        } else if (value instanceof Date) {
          acc[key] = value.toString()
        } else {
          acc[key] = String(value)
        }

        return acc
      },
      {},
      Object.entries(searchParams)
    )
  )

// https://dexie.org/docs/Tutorial/Best-Practices
import Dexie, { Table } from 'dexie'
import { isEmpty } from 'lodash'

import { CustomerOrderDeliveryDetailsItemIndexes } from './indexes/customerOrderDeliveryDetails'
import { DeliveryDetails } from './models/DeliveryDetails'
import { InventoryMoveOrderDeliveryDetailsItemIndexes } from './indexes/onlotInventoryOrderDeliveryDetails'
import { OrderListItem } from './models/OrderList'
import { OrderListItemIndexes } from './indexes/orderList'
import { listenForDatabaseEvents } from './utils/databaseEvents'
import { OnlotInventoryOrderDeliveryDetailsItemIndexes } from './indexes/inventoryMoveOrderDeliveryDetails'

const DATABASE_VERSION = 38

export class PWADB extends Dexie {
  OrderList!: Table<OrderListItem, number>
  CustomerOrderDeliveryDetails!: Table<DeliveryDetails, number>
  OnlotInventoryOrderDeliveryDetails!: Table<Partial<DeliveryDetails>, number>
  InventoryMoveOrderDeliveryDetails!: Table<Partial<DeliveryDetails>, number>

  constructor() {
    super('PWADB')
    this.version(DATABASE_VERSION).stores({
      OrderList: OrderListItemIndexes,
      CustomerOrderDeliveryDetails: CustomerOrderDeliveryDetailsItemIndexes,
      OnlotInventoryOrderDeliveryDetails: OnlotInventoryOrderDeliveryDetailsItemIndexes,
      InventoryMoveOrderDeliveryDetails: InventoryMoveOrderDeliveryDetailsItemIndexes,
    })
  }

  // order list
  async getOrderList() {
    return await this.OrderList.toCollection().toArray()
  }

  async getCustomerOrderListItem(orderId: number) {
    return await this.OrderList.where('key').equals(`co-${orderId}`).first()
  }

  async getOnlotInventoryOrderListItem(orderId: number) {
    return await this.OrderList.where('key').equals(`oio-${orderId}`).first()
  }

  async getInventoryMoveOrderListItem(orderId: number) {
    return await this.OrderList.where('id').equals(`mo-${orderId}`).first()
  }

  setOrderList(orderListItems: OrderListItem[]) {
    return this.transaction('rw', this.OrderList, async tx => {
      Promise.all([this.OrderList.clear(), this.OrderList.bulkAdd(orderListItems)])
    })
  }

  // all delivery details queries
  async getOrdersThatAreReadyToDeliver() {
    return this.transaction(
      'rw',
      this.CustomerOrderDeliveryDetails,
      this.OnlotInventoryOrderDeliveryDetails,
      this.InventoryMoveOrderDeliveryDetails,
      async tx => {
        const customerOrderReadyToDeliver = await this.CustomerOrderDeliveryDetails.filter(
          deliveryDetails => {
            return !!deliveryDetails.readyToDeliver
          }
        ).toArray()
        const onlotInventoryOrderReadyToDeliver =
          await this.OnlotInventoryOrderDeliveryDetails.filter(deliveryDetails => {
            return !!deliveryDetails.readyToDeliver
          }).toArray()
        const inventoryMoveOrderReadyToDeliver =
          await this.InventoryMoveOrderDeliveryDetails.filter(deliveryDetails => {
            return !!deliveryDetails.readyToDeliver
          }).toArray()

        return [
          ...inventoryMoveOrderReadyToDeliver,
          ...customerOrderReadyToDeliver,
          ...onlotInventoryOrderReadyToDeliver,
        ]
      }
    )
  }

  // customer order delivery details queries
  async getCustomerOrderDeliveryDetails(orderId: number) {
    return await this.CustomerOrderDeliveryDetails.where('customerOrderId').equals(orderId).first()
  }

  // customer order delivery details mutations
  updateCustomerOrderDeliveryDetails(orderId: number, deliveryDetails: Partial<DeliveryDetails>) {
    return this.transaction('rw', this.CustomerOrderDeliveryDetails, async tx => {
      const existingCustomerOrderDeliveryDetails =
        await this.getCustomerOrderDeliveryDetails(orderId)
      const found = !isEmpty(existingCustomerOrderDeliveryDetails)

      if (!!found) {
        await this.CustomerOrderDeliveryDetails.update(orderId, deliveryDetails)
        return await this.getCustomerOrderDeliveryDetails(orderId)
      } else {
        await this.CustomerOrderDeliveryDetails.add(deliveryDetails, orderId)
        return await this.getCustomerOrderDeliveryDetails(orderId)
      }
    })
  }

  deleteCustomerOrderDeliveryDetails(orderId: number) {
    const details = this.CustomerOrderDeliveryDetails.where('customerOrderId')
      .equals(orderId)
      .delete()
      .then(() => {
        console.log(`Deleted Customer Order Delivery Details, Order Number: ${orderId}`)
      })
    return details
  }

  // onlot inventory order delivery details queries
  async getOnlotInventoryOrderDeliveryDetails(orderId: number) {
    return await this.OnlotInventoryOrderDeliveryDetails.where('onlotInventoryOrderId')
      .equals(orderId)
      .first()
  }

  // inventory move order delivery details queries
  async getInventoryMoveOrderDeliveryDetails(orderId: number) {
    return await this.InventoryMoveOrderDeliveryDetails.where('inventoryMoveOrderId')
      .equals(orderId)
      .first()
  }

  // onlot inventory order delivery details mutations
  updateOnlotInventoryOrderDeliveryDetails(
    orderId: number,
    deliveryDetails: Partial<DeliveryDetails>
  ) {
    return this.transaction('rw', this.OnlotInventoryOrderDeliveryDetails, async tx => {
      const existingOnlotInventoryOrderDelvieryDetails =
        await this.getOnlotInventoryOrderDeliveryDetails(orderId)
      const found = !isEmpty(existingOnlotInventoryOrderDelvieryDetails)

      if (!!found) {
        await this.OnlotInventoryOrderDeliveryDetails.update(orderId, deliveryDetails)
        return this.getOnlotInventoryOrderDeliveryDetails(orderId)
      } else {
        await this.OnlotInventoryOrderDeliveryDetails.add(deliveryDetails, orderId)
        return this.getOnlotInventoryOrderDeliveryDetails(orderId)
      }
    })
  }

  // inventory move order delivery details mutations
  updateInventoryMoveOrderDeliveryDetails(
    orderId: number,
    deliveryDetails: Partial<DeliveryDetails>
  ) {
    return this.transaction('rw', this.InventoryMoveOrderDeliveryDetails, async tx => {
      const existingInventoryMoveOrderDelvieryDetails =
        await this.getInventoryMoveOrderDeliveryDetails(orderId)
      const found = !isEmpty(existingInventoryMoveOrderDelvieryDetails)

      if (!!found) {
        await this.InventoryMoveOrderDeliveryDetails.update(orderId, deliveryDetails)
        return this.getInventoryMoveOrderDeliveryDetails(orderId)
      } else {
        await this.InventoryMoveOrderDeliveryDetails.add(deliveryDetails, orderId)
        return this.getInventoryMoveOrderDeliveryDetails(orderId)
      }
    })
  }

  deleteOnlotInventoryOrderDeliveryDetails(orderId: number) {
    return this.OnlotInventoryOrderDeliveryDetails.where('onlotInventoryOrderId')
      .equals(orderId)
      .delete()
      .then(() => {
        console.log(`Deleted Onlot Inventory Order Delivery Details, Order Number: ${orderId}`)
      })
  }

  deleteInventoryMoveOrderDeliveryDetails(orderId: number) {
    return this.InventoryMoveOrderDeliveryDetails.where('inventoryMoveOrderId')
      .equals(orderId)
      .delete()
      .then(() => {
        console.log(`Deleted Inventory Move Order Delivery Details, Order Number: ${orderId}`)
      })
  }
}

export const db = new PWADB()

listenForDatabaseEvents()

import { populate } from './populate'
import { db } from '../index'

export function listenForDatabaseEvents() {
  db.on('versionchange', () => console.log('INDEXEDDB VERSION CHANGED'))
  db.on('blocked', () => console.log('INDEXEDDB BLOCKED'))
  db.on('close', () => console.log('INDEXEDDB CLOSED'))
  db.on('ready', () => console.log('INDEXEDDB READY'))
  db.on('populate', populate)
}

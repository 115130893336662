import { db } from '../../../db'
import { DeliveryDetails } from '../../../db/models/DeliveryDetails'

export async function clearDeliveryDetails(details: DeliveryDetails) {
  const { inventoryMoveOrderId, customerOrderId, onlotInventoryOrderId } = details

  if (!!customerOrderId) {
    db.deleteCustomerOrderDeliveryDetails(customerOrderId)
    return true
  } else if (!!inventoryMoveOrderId) {
    db.deleteInventoryMoveOrderDeliveryDetails(inventoryMoveOrderId)
    return true
  } else if (!!onlotInventoryOrderId) {
    db.deleteOnlotInventoryOrderDeliveryDetails(onlotInventoryOrderId)
    return true
  } else {
    console.error('CLEAR DELIVERY DETAILS NO ID FOUND!!')
    return false
  }
}

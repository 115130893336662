import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    constants: {
      toolbarHeight: number
      toolbarCompactHeight: number
    }
  }
  interface ThemeOptions {
    constants?: {
      toolbarHeight?: number
      toolbarCompactHeight?: number
    }
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    success: PaletteColor
    greyBackground: PaletteColor
  }
  interface PaletteOptions {
    success?: PaletteColorOptions
    greyBackground?: PaletteColorOptions
  }
}

const theme = createMuiTheme({
  constants: {
    toolbarHeight: 64,
    toolbarCompactHeight: 56,
  },
  shape: {
    borderRadius: 15,
  },
  palette: {
    primary: {
      main: '#03a9f4',
      contrastText: '#fff',
    },
    secondary: {
      main: 'hsl(335,100%,50%)',
    },
    success: {
      main: '#34b043',
    },
    warning: {
      main: 'rgb(250, 225, 0)',
    },
    error: {
      main: '#d01c2a',
    },
    background: {
      default: '#eaeaeb',
    },
    greyBackground: {
      main: '#eaeaeb',
    },
  },
})

theme.typography.h1 = {
  fontFamily: 'inherit',
  fontSize: '1.2rem',
  fontWeight: 500,
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textTransform: 'none',
}

theme.typography.h2 = {
  fontFamily: 'inherit',
  fontSize: '1rem',
  fontWeight: 'normal',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textTransform: 'none',
}

theme.typography.subtitle1 = {
  fontFamily: 'inherit',
  fontSize: '0.8rem',
  fontWeight: 'normal',
  fontStyle: 'normal',
  color: theme.palette.text.primary,
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textTransform: 'none',
}

export default theme

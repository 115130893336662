import { DeliveryDetails } from '../../../db/models/DeliveryDetails'
import { ImageType, Emails, OrderType } from '../../enums'
import { sendEmailNotification } from '../../helpers/email-notifications'
import { generateDeliveryCertificate } from '../../helpers/email-notifications/generateDeliveryCertificate'
import { upload } from '../../helpers/upload'
import { clearDeliveryDetails } from '../helpers/clearDeliveryDetails'
import { transformBuildingImagesForDelivery } from '../helpers/transformBuildingImagesForDelivery'
import { transformHappyListForDelivery } from '../helpers/transformHappyListForDelivery'
import { requestToDeliverCustomerOrderNoPaymentRequired } from './requests/deliverCustomerOrderNoPaymentRequired'
import { validateDeliverCustomerOrderNoPaymentRequiredRequiredFields } from './validation/deliverCustomerOrderNoPaymentRequired'

export async function deliverCustomerOrderNoPaymentRequired(deliveryDetails: DeliveryDetails) {
  const customerOrderId = deliveryDetails.customerOrderId
  validateDeliverCustomerOrderNoPaymentRequiredRequiredFields(deliveryDetails)

  const buildingImagesUploadResponse = await upload({
    base64Urls: deliveryDetails.uploadDataUrls,
    imageType: ImageType.BuildingImage,
    customerOrderId,
  })

  const [signatureUploadResponse] = await upload({
    base64Urls: [deliveryDetails.signatureDataUrl],
    imageType: ImageType.Signature,
    customerOrderId,
  })

  const deliveryResponse = await requestToDeliverCustomerOrderNoPaymentRequired({
    customerOrderId: customerOrderId,
    dateDelivered: deliveryDetails.dateDelivered,
    deliveryMilesDriven: deliveryDetails.deliveryMilesDriven,
    deliveryGpsLat: deliveryDetails.deliveryGpsLat,
    deliveryGpsLon: deliveryDetails.deliveryGpsLon,
    deliverySurveyAnswers: transformHappyListForDelivery(deliveryDetails.happyListResults),
    deliveryBegan: deliveryDetails.deliveryBegan,
    deliveryEnded: deliveryDetails.deliveryEnded,
    deliveryNote: deliveryDetails.deliveryNote || '',
    signatureGuid: signatureUploadResponse?.guid,
    signatureMimetype: signatureUploadResponse?.mimeType,
    signatureExtension: signatureUploadResponse?.extension,
    signatureUrl: signatureUploadResponse?.downloadUrls[0],
    signatureFrom: deliveryDetails.signatureFrom,
    buildingImages: transformBuildingImagesForDelivery(buildingImagesUploadResponse),
  })

  try {
    const deliveryCertificateUrl = await generateDeliveryCertificate({ orderId: customerOrderId })

    sendEmailNotification({
      type: Emails.ShedSuiteCustomerOrderDelivered,
      orderId: customerOrderId,
      orderType: OrderType.Customer,
      deliveryCertificateUrl,
    })

    sendEmailNotification({
      type: Emails.ShedSuiteOfficeAdminOrderDelivered,
      orderId: customerOrderId,
      orderType: OrderType.Customer,
    })
  } catch (error) {
    console.error('Failed to send delivery certificate / emails: ', error)
  }

  clearDeliveryDetails(deliveryDetails)

  return deliveryResponse
}

import { Logos, OrderType } from '../../enums'
import { TE, pipe } from '../../../common/helpers/fp-ts'
import { match } from 'ts-pattern'
import { EmailNotificationInput, sendEmail } from './index'
import { orderApi } from '../../../api/order'

function sendDriverReassignedCustomerOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getCustomerOrderDriverReassignedEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'Order Reassigned',
            messageIsHtml: true,
            recipient: d.assigned_driver_email,
            fromNoReply: true,
            tags: ['Driver - order reassigned'],
            message: `Hello ${d.assigned_driver_first_name},<br/>
                order number ${d.order_number} has been reassigned to
                driver ${d.new_assigned_driver_name}. <br/>
                Name: ${d.customer_name}<br/>
                Delivery Address: ${d.delivery_address}<br/>
                Model and Size: ${d.building_model}<br/>
                Serial Number: ${d.serial_number}<br/>
                Order Number: ${d.order_number}<br/>
                <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

function sendDriverReassignedInventoryMoveOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getDriverReassignedInventoryMoveOrderEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'Order Reassigned',
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: true,
            tags: ['Driver - order reassigned'],
            message: `Hello ${d.first_name},<br/>
                order number ${d.order_number} has been reassigned to
                driver ${d.new_assigned_driver}. <br/>
                Name: ${d.dealership_name}<br/>
                Delivery Address: ${d.delivery_address}<br/>
                Model and Size: ${d.building_model}<br/>
                Serial Number: ${d.serial_number}<br/>
                Order Number: ${d.order_number}<br/>
                <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

function sendDriverReassignedOnlotInventoryOrderEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getDriverReassignedOnlotInventoryOrderEmailInformation(input.orderId, input.driver_id),
    TE.chain(d =>
      d.send_email ? TE.right(d) : TE.left(new Error("Driver's email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: 'Order Reassigned',
            messageIsHtml: true,
            recipient: d.email,
            fromNoReply: true,
            tags: ['Driver - order reassigned'],
            message: `Hello ${d.first_name},<br/>
              order number ${d.order_number} has been reassigned to
              driver ${d.new_assigned_driver}. <br/>
              Name: ${d.dealership_name}<br/>
              Delivery Address: ${d.delivery_address}<br/>
              Model and Size: ${d.building_model}<br/>
              Serial Number: ${d.serial_number}<br/>
              Order Number: ${d.order_number}<br/>
              <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />`,
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

export async function sendDriverReassignEmail(input: EmailNotificationInput) {
  return await match(input.orderType)
    .with(OrderType.Customer, () =>
      pipe(
        sendDriverReassignedCustomerOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver reassigned to customer order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver reassigned to customer order email')
        )
      )()
    )
    .with(OrderType.MoveOrder, () =>
      pipe(
        sendDriverReassignedInventoryMoveOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver reassigned to inventory move order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver reassigned to inventory move order email')
        )
      )()
    )
    .with(OrderType.Inventory, () =>
      pipe(
        sendDriverReassignedOnlotInventoryOrderEmail(input),
        TE.match(
          error =>
            console.warn(
              `Failed to send driver reassigned to on-lot inventory order email\n Error: ${error.message}`
            ),
          () => console.log('Successfully sent driver reassigned to on-lot inventory order email')
        )
      )()
    )
    .exhaustive()
}

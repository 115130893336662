export function getImageAttributes(fileUpload) {
  const splitted = fileUpload.path.split('/')
  const guid = splitted.pop().split('.')[0]
  const extension = fileUpload.mimetype.split('/')[1]
  const mimeType = fileUpload.mimetype
  const downloadUrls = [fileUpload.path]

  return {
    guid,
    extension,
    mimeType,
    downloadUrls,
  }
}

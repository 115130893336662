import useEndOfUrlPath from './UseEndOfUrlPath'
import useParamsFromUrl from './UseParamsFromUrl'

// TODO: refactor / remove
const useOrderIdFromUrl = () => {
  let orderId: any = useEndOfUrlPath()
  let { inventoryMoveOrderParam, customerOrderParam, onlotInventoryOrderParam } = useParamsFromUrl([
    'customerOrder',
    'onlotInventoryOrder',
    'inventoryMoveOrder',
  ])
  if (!/^\d+$/.test(orderId)) {
    customerOrderParam = orderId.search('customer') === -1 ? false : true
    orderId = Number(orderId.split('-').reverse()[0])
  } else {
    orderId = Number(orderId)
  }

  if (!orderId) {
    return {
      customerOrderId: 0,
      onlotOrderId: 0,
      orderId: 0,
    }
  }

  let customerOrderId
  let onlotOrderId
  let moveOrderId

  if (!!customerOrderParam) {
    customerOrderId = Number(orderId)
  } else if (!!onlotInventoryOrderParam) {
    onlotOrderId = Number(orderId)
  } else if (!!inventoryMoveOrderParam) {
    moveOrderId = Number(orderId)
  } else {
    return {
      moveOrderId: 0,
      customerOrderId: 0,
      onlotOrderId: 0,
      orderId,
    }
  }

  return {
    moveOrderId,
    customerOrderId,
    onlotOrderId,
    orderId,
  }
}

export default useOrderIdFromUrl

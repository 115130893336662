import { useEffect } from 'react'
import { Backdrop, Typography } from '@material-ui/core'
import { indexedDBIsSupported } from '../helpers/pwa'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles'
import { unauthenticate, useGlobalDispatch } from '../../context/global'
import logomark from '../../assets/svg/logomark.svg'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            width: 40,
            height: 40,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
            backgroundColor: 'black'
        },
    }),
);

export const IndexedDBIsNotSupportedBackDrop = () => {
    const classes = useStyles()
    const globalDispatch = useGlobalDispatch()

    useEffect(() => {
        if (!indexedDBIsSupported) {
            unauthenticate({ dispatch: globalDispatch })
        }
    }, [globalDispatch])

    return (
        <Backdrop className={classes.backdrop} open={!indexedDBIsSupported}>
            <img className={classes.logo} src={logomark} alt="Shed suite" height={36} width={36} />
            <Typography>
                IndexedDB is not supported on this device
            </Typography>
        </Backdrop>
    )
}

import { BeamerConfig } from './types/beamer'
import { PayFields } from './types/payrix'

declare global {
  interface Window {
    PayFields: PayFields | undefined
    beamer_config: BeamerConfig
  }
}

type beamerConfigOptions = {
  user_id?: string
  user_email?: string
  user_firstname?: string
  user_lastname?: string
  company?: string
  filter?: string
}

export const beamerInitialize = (config: beamerConfigOptions) => {
  const url = 'https://app.getbeamer.com/js/beamer-embed.js'

  window.beamer_config = {
    product_id: 'zcHAnTAx26020',
    selector: 'beamerButton',
    button: false,
    ...config,
  }

  const head = document.getElementsByTagName('head')[0]
  const script: any = document.createElement('script')

  script.defer = 'defer'
  script.src = url
  head.appendChild(script)
}

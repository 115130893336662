import { isEmpty } from 'lodash'

import { DeliveryDetails } from '../../../../db/models/DeliveryDetails'
import { ErrorMessages } from '../../../enums'

export function commonDeliveryDetailsValidation({
  deliveryDetails,
}: {
  deliveryDetails: DeliveryDetails
}) {
  if (!!isEmpty(deliveryDetails.uploadDataUrls)) {
    throw new Error(ErrorMessages.DeliveryPhotoRequired)
  } else if (!deliveryDetails.deliveryMilesDriven) {
    throw new Error(ErrorMessages.DeliveryMilesRequired)
  } else if (!deliveryDetails.deliveryEnded || !deliveryDetails.deliveryBegan) {
    throw new Error(ErrorMessages.DeliveryTimeRequired)
  }
}

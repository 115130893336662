import { DeliveryDetails } from '../../../../db/models/DeliveryDetails'
import { ErrorMessages } from '../../../enums'

export function validateDeliverCustomerOrderNewCardRequiredFields(
  deliveryDetails: DeliveryDetails
) {
  if (!deliveryDetails.cardProfileId || !deliveryDetails.cardToken) {
    throw new Error(ErrorMessages.CreditCardNumberRequired)
  } else if (!deliveryDetails.cardExpMonth || !deliveryDetails.cardExpYear) {
    throw new Error(ErrorMessages.CreditCardExpirationDateRequired)
  } else if (!deliveryDetails.cardCvv) {
    throw new Error(ErrorMessages.CreditCardCVVRequired)
  }
}

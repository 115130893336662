import { createContext, ReactNode, useContext, useReducer } from 'react'
import { isEmpty, orderBy, uniqBy } from 'lodash'
import { not, and, or } from 'ramda'

import { OrderFilters, Filter } from '../interfaces'
import { OrderListAndOrderMapFiltersIdByName } from '../enums'
import { OrderListItem } from '../../db/models/OrderList'
import { db } from '../../db'
import { definitions } from '../../types/goapi'
import { match } from 'ts-pattern'

const orderListAndOrderMapFilters: Filter[] = [
  {
    name: 'New Customer Order',
    id: OrderListAndOrderMapFiltersIdByName.NewCustomerOrder,
    key: 'newCustomerOrder',
  },
  {
    name: 'New Inventory',
    id: OrderListAndOrderMapFiltersIdByName.NewInventory,
    key: 'onlotInventoryOrder',
  },
  {
    name: 'Move Inventory',
    id: OrderListAndOrderMapFiltersIdByName.MoveOrder,
    key: 'inventoryMoveOrder',
  },
  {
    name: 'Off Lot Order',
    id: OrderListAndOrderMapFiltersIdByName.OffLotOrder,
    key: 'offlotCustomerOrder',
  },
  {
    name: 'Scheduled',
    id: OrderListAndOrderMapFiltersIdByName.Scheduled,
    key: 'scheduled',
  },
  {
    name: 'Unscheduled',
    id: OrderListAndOrderMapFiltersIdByName.Unscheduled,
    key: 'unscheduled',
  },
  {
    name: 'Finished',
    id: OrderListAndOrderMapFiltersIdByName.Finished,
    key: 'finished',
  },
  {
    name: 'Unfinished',
    id: OrderListAndOrderMapFiltersIdByName.Unfinished,
    key: 'unfinished',
  },
  {
    name: 'Rush Order',
    id: OrderListAndOrderMapFiltersIdByName.RushOrder,
    key: 'rushOrder',
  },
]

type ActionTypes = 'APPLY_ORDER_LIST_AND_MAP_FILTERS' | 'APPLY_ORDER_LIST_AND_MAP_SEARCH_TERM'
type FiltersDispatch = ({ type }: { type: ActionTypes; value?: any }) => void

type FiltersState = {
  appliedOrderListAndOrderMapFilters: Filter[]
  allOrderListAndOrderMapFilters: Filter[]
  orderListAndOrderMapSearchTerm: string
}

type FiltersProviderProps = {
  children: ReactNode
}

const FiltersStateContext = createContext<FiltersState | undefined>(undefined)
const FiltersLogDispatchContext = createContext<FiltersDispatch | undefined>(undefined)

FiltersStateContext.displayName = 'FiltersStateContext'
FiltersLogDispatchContext.displayName = 'FiltersDispatchContext'

function FiltersReducer(
  state: FiltersState,
  action: {
    type: ActionTypes
    value?: { filterIds?: number[]; searchTerm?: string }
  }
) {
  const { type, value } = action

  return match(type)
    .with('APPLY_ORDER_LIST_AND_MAP_FILTERS', () => {
      const filtersToApply = uniqBy(
        state.allOrderListAndOrderMapFilters.filter(f => value.filterIds.find(id => f.id === id)),
        f => f.id
      )

      return { ...state, appliedOrderListAndOrderMapFilters: filtersToApply }
    })
    .with('APPLY_ORDER_LIST_AND_MAP_SEARCH_TERM', () => {
      return { ...state, orderListAndOrderMapSearchTerm: value.searchTerm }
    })
    .exhaustive()
}

function FiltersProvider({ children }: FiltersProviderProps) {
  const [state, dispatch] = useReducer(FiltersReducer, {
    appliedOrderListAndOrderMapFilters: [],
    allOrderListAndOrderMapFilters: orderListAndOrderMapFilters,
    orderListAndOrderMapSearchTerm: '',
  })

  return (
    <FiltersStateContext.Provider value={state}>
      <FiltersLogDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersLogDispatchContext.Provider>
    </FiltersStateContext.Provider>
  )
}

function useFiltersState() {
  const context = useContext(FiltersStateContext)
  if (context === undefined) {
    throw new Error(`useFiltersState must be used within a FiltersProvider`)
  }
  return context
}

function useFiltersDispatch() {
  const context = useContext(FiltersLogDispatchContext)
  if (context === undefined) {
    throw new Error(`useFiltersDispatch must be used within a FiltersProvider`)
  }
  return context
}

function applyOrderListAndOrderMapFilterLogic(
  filterId: number,
  appliedFilers: Filter[],
  options?: {
    toggle?: boolean
  }
) {
  const { toggle } = options ?? {}

  let filterIds = appliedFilers.map(filter => filter.id)

  const addFilter = (filterToAddId: number) => {
    filterIds = filterIds.concat([filterToAddId])
  }

  const removeFilter = (filterToRemoveId: number) => {
    filterIds = filterIds.filter(filterId => filterToRemoveId !== filterId)
  }

  const toggleFilter = (filterToToggleId: number) => {
    if (!!toggle) {
      !!filterIds.find(filterId => filterId === filterToToggleId)
        ? removeFilter(filterToToggleId)
        : addFilter(filterToToggleId)
    }
  }

  const filter = (filterId: number) => {
    !!toggle ? toggleFilter(filterId) : addFilter(filterId)
  }

  const {
    NewCustomerOrder,
    Scheduled,
    NewInventory,
    OffLotOrder,
    Unscheduled,
    Finished,
    Unfinished,
    RushOrder,
    MoveOrder,
  } = OrderListAndOrderMapFiltersIdByName

  switch (filterId) {
    case NewCustomerOrder:
      filter(filterId)
      removeFilter(NewInventory)
      break
    case OffLotOrder:
      filter(filterId)
      removeFilter(NewInventory)
      break
    case MoveOrder:
      filter(filterId)
      removeFilter(OffLotOrder)
      removeFilter(NewCustomerOrder)
      break
    case NewInventory:
      filter(filterId)
      removeFilter(OffLotOrder)
      removeFilter(NewCustomerOrder)
      break
    case Scheduled:
      filter(filterId)
      removeFilter(Unscheduled)
      break
    case Unscheduled:
      filter(filterId)
      removeFilter(Scheduled)
      break
    case Finished:
      filter(filterId)
      removeFilter(Unfinished)
      break
    case Unfinished:
      filter(filterId)
      removeFilter(Finished)
      break
    case RushOrder:
      filter(filterId)
      break
    default:
      console.error(`filter ID ${filterId} is not a valid ID.`)
  }

  return filterIds
}

function mapOrderFilters(filters: Filter[]): OrderFilters {
  const keys = filters.map(filter => filter.key)

  let filter: OrderFilters = {}

  keys.forEach(key => {
    filter[`is${key[0].toUpperCase()}${key.slice(1)}`] = true
  })

  return filter
}

function filterOrderList(
  orderListItems: OrderListItem[],
  searchTerm?: string,
  filters?: OrderFilters
) {
  const has_filters = !isEmpty(filters)
  const has_searchterm = !isEmpty(searchTerm)
  const has_order_list_items = !isEmpty(orderListItems)
  if (!has_filters && !has_searchterm) return orderListItems
  if (!has_order_list_items) return orderListItems

  let filtered = orderListItems

  if (has_filters) {
    filtered = filterByCheckedFilters({ orderListItems, filters })
  }

  if (has_searchterm) {
    filtered = filterBySearchTerm({
      orderListItems: filtered,
      searchTerm,
    })
  }

  if (!!filters.isScheduled) {
    filtered = orderBy(filtered, ['scheduled_date'], ['asc'])
  }

  return filtered
}

function includeNewCustomerOrder({
  filters,
  orderTypeIncludesNewCustomerOrder,
}: {
  filters: OrderFilters
  orderTypeIncludesNewCustomerOrder: boolean
}) {
  return and(filters.isNewCustomerOrder, orderTypeIncludesNewCustomerOrder)
}

function includeOfflotCustomerOrder({
  filters,
  orderTypeIncludesOfflotCustomerOrder,
}: {
  filters: OrderFilters
  orderTypeIncludesOfflotCustomerOrder: boolean
}) {
  return and(filters.isOfflotCustomerOrder, orderTypeIncludesOfflotCustomerOrder)
}

function includeOnlotInventoryOrder({
  filters,
  orderTypeIncludesOnlotInventoryOrder,
}: {
  filters: OrderFilters
  orderTypeIncludesOnlotInventoryOrder: boolean
}) {
  return and(filters.isOnlotInventoryOrder, orderTypeIncludesOnlotInventoryOrder)
}

function includeScheduledOrder({
  filters,
  orderStateIncludesScheduled,
}: {
  filters: OrderFilters
  orderStateIncludesScheduled: boolean
}) {
  return and(filters.isScheduled, orderStateIncludesScheduled)
}

function includeUnscheduledOrder({
  filters,
  orderStateIncludesScheduled,
}: {
  filters: OrderFilters
  orderStateIncludesScheduled: boolean
}) {
  return and(filters.isUnscheduled, not(orderStateIncludesScheduled))
}

function includeFinishedOrder({
  filters,
  orderStateIncludesFinished,
  orderStateIncludesScheduled,
}: {
  filters: OrderFilters
  orderStateIncludesFinished: boolean
  orderStateIncludesScheduled: boolean
}) {
  return and(filters.isFinished, or(orderStateIncludesFinished, orderStateIncludesScheduled))
}

function includeUnfinishedOrder({
  filters,
  orderStateIncludesFinished,
  orderStateIncludesScheduled,
}: {
  filters: OrderFilters
  orderStateIncludesFinished: boolean
  orderStateIncludesScheduled: boolean
}) {
  return and(filters.isUnfinished, not(or(orderStateIncludesFinished, orderStateIncludesScheduled)))
}

function includeRushOrder({
  filters,
  isRushOrder,
}: {
  filters: OrderFilters
  isRushOrder: boolean
}) {
  return and(filters.isRushOrder, isRushOrder)
}

function includeInventoryMoveOrder({
  filters,
  isInventoryMoveOrder,
}: {
  filters: OrderFilters
  isInventoryMoveOrder: boolean
}) {
  return and(filters.isInventoryMoveOrder, isInventoryMoveOrder)
}

function filterByCheckedFilters({
  orderListItems,
  filters,
}: {
  orderListItems: OrderListItem[]
  filters: OrderFilters
}) {
  return orderListItems.filter(item => {
    let retval = true

    const { is_scheduled_for_delivery, is_built, order_type } = item
    const is_new_build_customer_order = order_type === 'new build'
    const is_off_lot_customer_order = order_type === 'off-lot inventory'
    const is_on_lot_inventory_order = order_type === 'on-lot inventory'
    const is_inventory_move_order = order_type === 'move order'

    if (filters.isScheduled) {
      retval = and(
        retval,
        includeScheduledOrder({ filters, orderStateIncludesScheduled: is_scheduled_for_delivery })
      )
    }
    if (filters.isUnscheduled) {
      retval = and(
        retval,
        includeUnscheduledOrder({ filters, orderStateIncludesScheduled: is_scheduled_for_delivery })
      )
    }
    if (filters.isFinished) {
      retval = and(
        retval,
        includeFinishedOrder({
          filters,
          orderStateIncludesFinished: is_built,
          orderStateIncludesScheduled: is_scheduled_for_delivery,
        })
      )
    }
    if (filters.isUnfinished) {
      retval = and(
        retval,
        includeUnfinishedOrder({
          filters,
          orderStateIncludesFinished: is_built,
          orderStateIncludesScheduled: is_scheduled_for_delivery,
        })
      )
    }
    if (filters.isNewCustomerOrder) {
      retval = and(
        retval,
        includeNewCustomerOrder({
          filters,
          orderTypeIncludesNewCustomerOrder: is_new_build_customer_order,
        })
      )
    }
    if (filters.isOfflotCustomerOrder) {
      retval = and(
        retval,
        includeOfflotCustomerOrder({
          filters,
          orderTypeIncludesOfflotCustomerOrder: is_off_lot_customer_order,
        })
      )
    }
    if (filters.isOnlotInventoryOrder) {
      retval = and(
        retval,
        includeOnlotInventoryOrder({
          filters,
          orderTypeIncludesOnlotInventoryOrder: is_on_lot_inventory_order,
        })
      )
    }
    if (filters.isRushOrder) {
      retval = and(retval, includeRushOrder({ filters, isRushOrder: item.is_rush_order }))
    }
    if (filters.isInventoryMoveOrder) {
      retval = and(
        retval,
        includeInventoryMoveOrder({ filters, isInventoryMoveOrder: !!is_inventory_move_order })
      )
    }

    return retval
  })
}

function filterBySearchTerm({
  orderListItems,
  searchTerm,
}: {
  orderListItems: OrderListItem[]
  searchTerm: string
}) {
  return orderListItems.filter(order =>
    [
      order.dealer_name,
      order.customer_name,
      order.building_model,
      order.serial_number,
      order.order_type,
      order.order_status,
      order.building_stage,
      order.building_location,
    ]
      .filter(Boolean)
      .some(v => v.toLowerCase().includes(searchTerm.toLowerCase()))
  )
}

async function filterOrderMap({
  orderMap,
  searchTerm,
  filters,
}: {
  orderMap: definitions['materializer.DriverAppOrderMap']
  searchTerm?: string
  filters?: any
}) {
  let orderList = await db.getOrderList()
  orderList = filterOrderList(orderList, searchTerm, filters)

  const filteredOrders = orderMap.orders.filter(order => {
    const { customer_order_id, onlot_inventory_order_id, inventory_move_order_id } = order

    return orderList.some(
      orderListItem =>
        (!!orderListItem.customer_order_id &&
          orderListItem.customer_order_id === customer_order_id) ||
        (!!orderListItem.onlot_inventory_order_id &&
          orderListItem.onlot_inventory_order_id === onlot_inventory_order_id) ||
        (!!orderListItem.inventory_move_order_id &&
          orderListItem.inventory_move_order_id === inventory_move_order_id)
    )
  })

  let filteredDealers = orderMap.dealers
  let filteredShops = orderMap.shops

  if (!!searchTerm) {
    filteredDealers = filteredDealers.filter(dealer =>
      dealer.dealership_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    filteredShops = filteredShops.filter(shop =>
      shop.shop_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  orderMap.orders = filteredOrders
  orderMap.dealers = filteredDealers
  orderMap.shops = filteredShops

  return orderMap
}

function applyOrderListAndOrderMapSearchTerm({
  dispatch,
  searchTerm,
}: {
  dispatch: FiltersDispatch
  searchTerm: string
}) {
  dispatch({
    type: 'APPLY_ORDER_LIST_AND_MAP_SEARCH_TERM',
    value: { searchTerm },
  })
}

export {
  FiltersProvider,
  OrderListAndOrderMapFiltersIdByName,
  applyOrderListAndOrderMapFilterLogic,
  filterOrderList,
  filterOrderMap,
  mapOrderFilters,
  orderListAndOrderMapFilters,
  useFiltersDispatch,
  useFiltersState,
  applyOrderListAndOrderMapSearchTerm,
}

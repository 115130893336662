import { useLocation } from 'react-router-dom'

const useEndOfUrlPath = () => {
    const location = useLocation()

    return location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    )
}

export default useEndOfUrlPath

import { HappyListResults } from '../../../db/models/DeliveryDetails'

export function transformHappyListForDelivery(happyListResults: HappyListResults) {
  return (happyListResults || []).map(e => {
    const yesNoValue = e.value?.toString()
    const freeValue = e.text
    const answer = e.item.questionType === 'YESNO' ? yesNoValue : freeValue

    return {
      driver_delivery_survey_question_id: e.item.driverDeliverySurveyQuestionId,
      answer,
    }
  })
}

import { EmailNotificationInput } from '.'
import { Logos } from '../../enums'
import { sendEmail } from './index'
import { TE, pipe } from '../../../common/helpers/fp-ts'
import { orderApi } from '../../../api/order'

export function sendRtoOrderDeliveredEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getRtoOrderDeliveredEmailInformation(input.orderId),
    TE.chain(dd =>
      TE.sequenceArray(
        dd
          .filter(d => !!d.send_email)
          .map(d =>
            TE.tryCatch(
              () =>
                sendEmail({
                  subject: `${d.company_name} has just delivered an RTO order for ${d.rto_company_name}`,
                  messageIsHtml: true,
                  recipient: d.email,
                  fromNoReply: true,
                  tags: ['RTO Admin - RTO order delivered'],
                  message: `
                    Hello ${d.first_name}, ${d.company_name} has just delivered this ${d.building_model} to
                    ${d.customer_name} at ${d.customer_delivery_address}!<br/>
                    Serial Number: ${d.serial_number}<br/>
                    Login to your <a href="https://app.shedsuite.com">RTO Account</a> so see this order.<br/>
                    <br/>
                    <img src=${Logos.EmailLogo} alt='shed suite logo' style='width:20%;height:auto;' />
                `,
                }),
              () => new Error(`Failed to send email`)
            )
          )
      )
    ),
    TE.chain(dd =>
      dd.every(d => d.success)
        ? TE.right(true)
        : TE.left(new Error('Failed to send one or more emails'))
    )
  )
}

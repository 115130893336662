import { parse } from 'psl'
import { JWT_STORAGE_KEY } from '../../context/global'

export const deleteCookie = name => {
  let domain: string
  let cookie: string
  const parsed = parse(window.location.host)
  if (parsed && parsed.error) {
    domain = window.location.host.substr(0, window.location.host.indexOf(':'))
    cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${domain};`
  } else {
    if (name === JWT_STORAGE_KEY) {
      cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/;`
    } else {
      domain = `.${parsed.domain}`
      cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${domain}; Secure;`
    }
  }

  document.cookie = cookie
}

export const setCookie = (name, value) => {
  const optionsWithDefaults = {
    days: 7,
    path: '/',
  }

  const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString()

  document.cookie = `${name}=${encodeURIComponent(value)}; Expires=${expires}; Path=${
    optionsWithDefaults.path
  };`
}

import { FileUploadAttributes } from '../../helpers/upload'
import { BuildingImages } from '../../interfaces'

export function transformBuildingImagesForDelivery(
  buildingImages: FileUploadAttributes[]
): BuildingImages {
  return (buildingImages || []).map(image => ({
    guid: image.guid,
    extension: image.extension,
    mime_type: image.mimeType,
    download_urls: image.downloadUrls,
  }))
}

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  Check = 'Check',
  Cash = 'Cash',
  NoPayment = 'NoPayment',
}

export enum CardSelection {
  NewCard = 'NewCard',
  ExistingCard = 'ExistingCard',
}

export enum CardStatus {
  Approved = 'Approved',
  Retry = 'Retry',
  Declined = 'Declined',
}

export enum CheckSelection {
  NewCheck = 'NewCheck',
  SavedCheck = 'SavedCheck',
}

import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'

import { EmailNotificationInput } from '.'
import { TE } from '../../../common/helpers/fp-ts'
import { sendEmail } from './index'
import { orderApi } from '../../../api/order'

export function sendCustomerOrderScheduledEmail(input: EmailNotificationInput) {
  return pipe(
    orderApi.getCustomerOrderScheduledEmailInformation(input.orderId),
    TE.chain(d =>
      d.send_email
        ? TE.right(d)
        : TE.left(new Error("Customer's order scheduled email setting is turned off"))
    ),
    TE.chain(d =>
      TE.tryCatch(
        () =>
          sendEmail({
            subject: `Hi ${d.customer_first_name}, we have scheduled your order!`,
            messageIsHtml: true,
            recipient: d.customer_email,
            fromNoReply: false,
            tags: ['Customer - order scheduled'],
            senderName: `${d.company_name}`,
            senderEmail: `${d.company_email}`,
            template: 'customer-order-scheduled',
            variables: {
              companyAddress: d.company_address,
              companyEmail: d.company_email,
              companyLogo: d.company_logo_url,
              companyName: d.company_name,
              companyPhone: d.company_phone,
              customerOrDealerName: d.dealer_name,
              dealerUserEmail: d.dealer_user_email,
              dealerUserName: d.dealer_user_name,
              dealerUserPhone: d.dealer_user_phone,
              deliveryDate: moment(d.delivery_date).format(`dddd MMMM Do`),
              modelAndSize: d.building_model,
            },
          }),
        () => new Error(`Failed to send email`)
      )
    ),
    TE.chain(d => (d.success ? TE.right(true) : TE.left(new Error(d.error))))
  )
}

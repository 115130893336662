export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Rejected = 'rejected',
  Resolved = 'resolved',
}

export enum QueryEndPoints {
  Notifications = '/api/notifications',
  CustomerOrderDetails = '/api/driver/customer-order-details',
  OnlotInventoryOrderDetails = '/api/driver/onlot-inventory-order-details',
  InventoryMoveOrderDetails = '/api/driver/inventory-move-order-details',
  CustomerOrderDeliveryDetails = '/api/driver/customer-order-delivery-details',
  InventoryOrderDeliveryDetails = '/api/driver/onlot-inventory-order-delivery-details',
  InventoryMoveOrderDeliveryDetails = '/api/driver/inventory-move-order-delivery-details',
  CompanyUserIsRemoved = '/api/company-user-is-removed',
  DriverSettings = '/api/driver/driver-settings',
  LogList = '/api/driver/log-list',
  LogListExport = '/api/driver/exports/log-list',
  DeliveryStats = '/api/driver/delivery-stats',
  UndeliveredOrdersStats = '/api/driver/undelivered-orders-stats',
  EmailCustomerOrderDelivered = '/api/materializer/customer-order-delivered-email-information',
  EmailCustomerOrderScheduled = '/api/materializer/customer-order-scheduled-email-information',
  EmailDriverAssignedCustomerOrder = '/api/materializer/customer-order-driver-assigned-email-information',
  EmailDriverAssignedInventoryMoveOrder = '/api/materializer/driver-assigned-inventory-move-order-email-information',
  EmailDriverAssignedOnlotInventoryOrder = '/api/materializer/driver-assigned-onlot-inventory-order-email-information',
  EmailDriverReassignedCustomerOrder = '/api/materializer/customer-order-driver-reassigned-email-information',
  EmailDriverReassignedInventoryMoveOrder = '/api/materializer/driver-reassigned-inventory-move-order-email-information',
  EmailOfficeAdminCustomerOrderDelivered = '/secure/report/email_office_admin_customer_order_delivered',
  EmailOfficeAdminInventoryMoveOrderDelivered = '/api/materializer/office-admin-inventory-move-order-delivered-email-information',
  EmailDriverReassignedOnlotOrder = '/api/materializer/driver-reassigned-onlot-inventory-order-email-information',
  EmailOfficeAdminOnlotInventoryOrderDelivered = '/api/materializer/office-admin-onlot-inventory-order-delivered-email-information',
  EmailRtoOrderDelivered = '/api/materializer/rto-order-delivered-email-information',
  OrderList = '/api/driver/order-list',
  OrderMap = '/api/materializer/driver-app-order-map',
  ProfileInquery = '/secure/cardconnect/profile_inquiry',
  SavedCustomerOrderChecks = '/api/materializer/saved-customer-order-checks',
  ScheduleList = '/api/materializer/driver-app-schedule-list',
  CustomerOrderScheduleDetails = '/api/materializer/driver-app-schedule-details-customer-order',
  OnlotInventoryOrderScheduleDetails = '/api/materializer/driver-app-schedule-details-onlot-inventory-order',
  InventoryMoveOrderScheduleDetails = '/api/materializer/driver-app-schedule-details-inventory-move-order',
  CheckFeatureFlag = '/rmaembed/check-feature-flag',
}

export enum CommandEndPoints {
  AuthNewCard = '/secure/cardconnect/auth_new_card',
  RMAAuthNewCard = '/api/payments/auth-new-card',
  CreateOrderNote = '/secure/driver/create_order_note',
  DeliverCustomerOrderCash = '/secure/driver/deliver_customer_order_cash',
  DeliverCustomerOrderExistingCard = '/secure/driver/deliver_customer_order_existing_card',
  DeliverCustomerOrderNewCard = '/secure/driver/deliver_customer_order_new_card',
  DeliverCustomerOrderNewCheck = '/secure/driver/deliver_customer_order_new_check',
  DeliverCustomerOrderNoPaymentRequired = '/secure/driver/deliver_customer_order_no_payment_required',
  DeliverCustomerOrderRto = '/secure/driver/deliver_customer_order_rto',
  DeliverCustomerOrderSavedCheck = '/secure/driver/deliver_customer_order_saved_check',
  DeliverOnlotInventoryOrderNew = '/secure/driver/deliver_onlot_inventory_order_new',
  DeliverInventoryMoveOrder = '/secure/driver/deliver_inventory_move_order',
  GenerateDeliveryCertificate = '/secure/driver/generate_delivery_certificate',
  Login = '/login',
  ReassignDriver = '/secure/driver/reassign_driver',
  ScheduleMyCustomerOrderDelivery = '/secure/driver/schedule_my_customer_order_delivery',
  ScheduleMyOnlotInventoryOrderDelivery = '/secure/driver/schedule_my_onlot_inventory_order_delivery',
  ScheduleMyInventoryMoveOrderDelivery = '/secure/driver/schedule_my_inventory_move_order_delivery',
  SendEmail = '/send-email',
  UnscheduleOrder = '/secure/driver/unschedule_order',
  UpdateCustomerOrderScheduledDateAndTime = '/secure/driver/update_customer_order_scheduled_date_and_time',
  UpdateDriverSettings = '/secure/driver/update_driver_settings',
  UpdateOnlotInventoryOrderScheduledDateAndTime = '/secure/driver/update_onlot_inventory_order_scheduled_date_and_time',
  UpdateInventoryMoveOrderScheduledDateAndTime = '/secure/driver/update_inventory_move_order_scheduled_date_and_time',
  Upload = '/upload',
  CreateNotification = '/secure/driver/create_notification',
  DismissAllNotifications = '/secure/driver/dismiss_all_notifications',
  DismissNotification = '/secure/driver/notification_dismiss',
  UpdateDriverEmailNotifications = '/secure/driver/update_driver_email_notifications',
}

export enum Emails {
  ShedSuiteCustomerOrderDelivered = 'SHEDSUITE_CUSTOMER_ORDER_DELIVERED',
  ShedSuiteCustomerOrderScheduled = 'SHEDSUITE_CUSTOMER_ORDER_SCHEDULED',
  ShedSuiteDriverAssigned = 'SHEDSUITE_DRIVER_ASSIGNED',
  ShedSuiteDriverReassign = 'SHEDSUITE_DRIVER_REASSIGN',
  ShedSuiteOfficeAdminOrderDelivered = 'SHEDSUITE_OFFICE_ADMIN_ORDER_DELIVERED',
  ShedSuiteRtoOrderDelivered = 'SHEDSUITE_RTO_ORDER_DELIVERED',
}

export enum Logos {
  EmailLogo = 'https://www.shedsuite.com/img/email/logo.png',
}

export enum OrderType {
  Customer = 'customer',
  Inventory = 'inventory',
  MoveOrder = 'move order',
}

export enum Errors {
  Login = 'Global error: login error ',
  ToManyFiles = 'Too many files',
}

export enum ErrorMessages {
  FailedToFilterOrderMap = 'Failed to filter order map',
  BucketToUploadPhoto = 'Error setting bucket to upload photo',
  CardSelectionTypeIsRequired = 'Credit card type is required',
  CheckSelectionTypeIsRequired = 'Check type is required',
  CreditCardCVVRequired = 'Credit card CVV is required',
  CreditCardExpirationDateRequired = 'Credit card expiration date is required',
  CreditCardNumberRequired = 'Credit card number is required',
  CustomerOrderDeliveryEmailInfo = 'Failed to get customer order delivered email information',
  CustomerOrderScheduledEmailInfo = 'Failed to get customer order scheduled email information',
  DeliveryLocationNotSet = 'Delivery location has not been set',
  DeliveryMilesRequired = 'Delivery miles required',
  DeliveryPhotoRequired = 'Delivery photo(s) required',
  DeliverySignatureRequired = 'A signature is required',
  DeliverySurveyAnswersNotSet = 'Delivery survey answers have not been set',
  DeliveryTimeRequired = 'Delivery time required',
  DriverAssignedEmailInfo = 'Failed to get driver assigned email information',
  DriverReassignEmailInfo = 'Failed to get driver reassigned email information',
  ErrorAuthenticatingCard = 'Error authenticating card',
  ErrorSchedulingDelivery = 'Error scheduling delivery',
  FailedToCreateNote = 'Failed to add note',
  FailedToDeliverInventoryOrder = 'Failed to deliver inventory order',
  FailedToDeliverInventoryMoveOrder = 'Failed to deliver inventory move order',
  FailedToDeliverOrderWithACheck = 'Failed to deliver order with a check',
  FailedToDeliverOrderWithANewCard = 'Failed to deliver order with a new card',
  FailedToDeliverOrderWithAnExistingCard = 'Failed to deliver order with an existing card',
  FailedToDeliverOrderWithCash = 'Failed to deliver order with cash',
  FailedToDeliverOrderWithNoPayment = 'Failed to deliver order with no payment',
  FailedToDeliverRtoOrder = 'Failed to deliver rto order',
  FailedToDismissAllNotifications = 'Failed to clear all notifications',
  FailedToDismissNotification = 'Failed to dismiss notification',
  FailedToExportDeliveryLog = 'Failed to export delivery log',
  FailedToFetchAllNotifications = 'Failed to fetch all notifications',
  FailedToFetchCustomerOrder = 'Failed to fetch customer order details',
  FailedToFetchDeliveryLog = 'Failed to fetch delivery log',
  FailedToFetchDeliveryLogFilters = 'Failed to fetch delivery list filters',
  FailedToFetchDeliveryStats = 'Failed to fetch delivery stats',
  FailedToFetchHappyList = 'Failed to fetch happy list',
  FailedToFetchNewNotifications = 'Failed to fetch new notifications',
  FailedToFetchOnlotInventoryOrder = 'Failed to fetch inventory order details',
  FailedToFetchInventoryMoveOrder = 'Failed to fetch inventory move order details',
  FailedToFetchOrderList = 'Failed to fetch order list',
  FailedToFetchOrderMap = 'Failed to fetch order map',
  FailedToFetchUndeliveredOrdersStats = 'Failed to fetch order list stats',
  FailedToFetchSavedChecks = 'Failed to fetch saved checks',
  FailedToFetchScheduleDetails = 'Failed to fetch scheduled order details',
  FailedToFetchScheduleList = 'Failed to fetch scheduled orders',
  FailedToFindDeliveryDetails = 'Failed to find delivery details',
  FailedToFetchDeliveryDetails = 'Failed to fetch delivery details',
  FailedToGenerateDeliveryCertificate = 'Failed to generate delivery certificate',
  FailedToNotifyCompanyUsers = 'Failed to notify company users',
  FailedToReassignDriver = 'Failed to reassign order',
  FailedToRefreshDriverSettings = 'Failed to refresh settings',
  FailedToSaveDriverNotificationSettings = 'Failed to update notification settings',
  FailedToSaveDriverSettings = 'Failed to save settings',
  FailedToScheduleDelivery = 'Failed to schedule delivery',
  FailedToUnscheduleOrder = 'Failed to unschedule order',
  FailedToUpdateScheduledDateAndTime = 'Failed to reschedule delivery',
  GeneralDeliveryfailure = 'Failed to deliver order',
  GeneralError = 'There was an error',
  ImageIsTooLarge = 'Some files were rejected because they were too large.',
  IncorrectEmailOrPassword = 'Email or password is incorrect',
  InvalidAccountNumber = 'Invalid Account Number',
  InvalidCreditCardNumber = 'Invalid Credit Card Number',
  InvalidPageComponentVarient = 'Invalid page component varient',
  NoOrderIdFound = 'No order ID found',
  NotAuthenticated = 'Not authenticated',
  NotAuthorized = 'Not authorized',
  NotFound = 'Not found',
  OfficeAdminCustomerOrderDeliveredEmailInfo = 'Failed to office admin customer order delivered email information',
  PaymentAmountIsNan = 'Payment amount is not a number',
  PaymentMethodNotImplemented = 'Payment method not implemented',
  PaymentMethodRequired = 'A payment method is required',
  PhotoTypeNotSet = 'No photo type set',
  ReassigningDriver = 'An error has occured when reassigning driver',
  RtoOrderDeliveredEmailInfo = 'Failed to get rto order delivered email information',
  SavedCheckDoesNotHaveAnId = 'Selected saved check does not have an ID',
  WrongImageExtension = 'Wrong image extension',
}

export enum SuccessMessages {
  notificationSettings = 'Notification settings updated',
  orderRescheduled = 'Order rescheduled',
  orderScheduled = 'Order scheduled',
  orderUnscheduled = 'Order unscheduled',
}

export enum WarningMessages {
  deliverOrder = 'Attempting to deliver order',
  notificationSettings = 'Attempting to update notification settings',
  rescheduleOrder = 'Attempting to reschedule order',
  scheduleOrder = 'Attempting to schedule order',
  unscheduleOrder = 'Attempting to unschedule order',
}

export enum PagePaths {
  CreateNote = '/create-note',
  DeliveryDetails = '/delivery-details',
  DeliveryLog = '/delivery-log',
  EditSettings = '/settings/edit',
  Home = '/home',
  Notifications = '/notifications',
  CustomerOrderDetails = '/customer-order-details',
  OnlotInventoryOrderDetails = '/onlot-inventory-order-details',
  InventoryMoveOrderDetails = '/inventory-move-order-details',
  OrderMap = '/map',
  CustomerOrderScheduleDetails = '/customer-order-schedule-details',
  OnlotInventoryOrderScheduleDetails = '/onlot-inventory-order-schedule-details',
  InventoryMoveOrderScheduleDetails = '/inventory-move-order-schedule-details',
  ScheduleOrder = '/schedule',
  Scheduled = '/scheduled',
  Settings = '/settings',
  SignIn = '/',
  TechSupport = '/tech-support',
}

export enum ImageUploads {
  MaxDeliveryBuildingImages = 5,
}

export enum ImageType {
  BuildingImage = 'Building',
  Check = 'Check',
  ProfileImage = 'Profile',
  Signature = 'Signature',
}

export enum GcsBucketOrigin {
  DeliverCustomerOrder = '/deliver-customer-order',
  DeliverOnlotInventoryOrder = '/deliver-onlot-inventory-order',
  ProfilePictures = '/profile-pictures',
}

export enum QueryKeys {
  Notifications = 'notifications',
  DeliveryStats = 'deliveryStats',
  GetOrderListItemById = 'getOrderListItemById',
  LogList = 'logList',
  LogListFilters = 'logListFilters',
  OrderDetails = 'orderDetails',
  OrderList = 'orderList',
  OrderMap = 'orderMap',
  UndeliveredOrdersStats = 'undeliveredOrdersStats',
  ScheduleDetail = 'scheduleDetail',
  ScheduleList = 'scheduleList',
  Settings = 'settings',
  DeliveryDetails = 'deliveryDetails',
}

export enum PaymentMethod {
  Cash = 'Cash',
  Check = 'Check',
  CreditCard = 'CreditCard',
  NoPayment = 'NoPayment',
}

export enum CardSelection {
  ExistingCard = 'ExistingCard',
  NewCard = 'NewCard',
}

export enum SignatureType {
  signAsCustomer = 'customer',
  signAsDriver = 'driver',
}

export enum OrderListAndOrderMapFiltersIdByName {
  NewCustomerOrder = 1,
  NewInventory = 2,
  OffLotOrder = 3,
  Scheduled = 4,
  Unscheduled = 5,
  Unfinished = 6,
  Finished = 7,
  RushOrder = 8,
  MoveOrder = 9,
}

export enum UserRole {
  SHEDSUITE_CUSOMER = 'shedsuite_customer',
  SHEDSUITE_DEALER = 'shedsuite_dealer',
  SHEDSUITE_DISPTACH = 'shedsuite_disptach',
  SHEDSUITE_DRIVER = 'shedsuite_driver',
  SHEDSUITE_FINANCING = 'shedsuite_financing',
  SHEDSUITE_MANUFACTURING = 'shedsuite_manufacturing',
  SHEDSUITE_OFFICE_ADMIN = 'shedsuite_office_admin',
  SHEDSUITE_RTO = 'shedsuite_rto',
  SHEDSUITE_SALES_REP = 'shedsuite_sales_rep',
  SHEDSUITE_SHOP_STAFF = 'shedsuite_shop_staff',
  SHEDSUITE_SUPER_ADMIN = 'shedsuite_super_admin',
}

export enum FeatureFlag {
  PAYMENTS_V2 = 'payments_v2',
}

export enum Service {
  REMIX = 'remix',
  REPORT = 'report',
  CARD_CONNECT = 'card_connect',
  GOAPI = 'goapi',
  WEB_APP = 'web_app',
  DRIVER = 'driver',
}

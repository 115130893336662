import useOrderIdFromUrl from '../../../common/hooks/UseOrderIdFromUrl'
import useParamsFromUrl from '../../../common/hooks/UseParamsFromUrl'
import { OrderType } from '../../../context/enums'

export const useOrderType = () => {
  const { inventoryMoveOrderParam, onlotInventoryOrderParam, customerOrderParam } =
    useParamsFromUrl(['inventoryMoveOrder', 'onlotInventoryOrder', 'customerOrder'])
  const { customerOrderId, onlotOrderId, moveOrderId } = useOrderIdFromUrl()
  const isInventoryMoveOrder = inventoryMoveOrderParam === 'true' || !!moveOrderId
  const isOnlotInventoryOrder = onlotInventoryOrderParam === 'true' || !!onlotOrderId
  const isCustomerOrder = customerOrderParam === 'true' || !!customerOrderId

  if (isInventoryMoveOrder) {
    return OrderType.MoveOrder
  }

  if (isOnlotInventoryOrder) {
    return OrderType.Inventory
  }

  if (isCustomerOrder) {
    return OrderType.Customer
  }

  return null
}
